import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Option } from "app/_shared/types";
import { IMilestone } from "app/_shared/types/models/milestone";
import {
  EOrderSubType,
  EOrderType,
  IOrder,
  IOrderItemStat,
  IOrderStat,
  IOrderStatCosts,
} from "app/_shared/types/models/order";
import { OrderInput } from "app/_shared/types/order-input";
import { environment } from "environments/environment";
import { switchMap, forkJoin, map, Subject, debounceTime } from "rxjs";
import { range, orderBy, isArray } from "lodash";
import { parseISO, format } from "date-fns";
import { IDocument } from "app/_shared/types/models/document";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  private logSubject = new Subject<void>();
  private logMap: Record<string, any[]> = {};

  apiUrl: string = environment.api + "api/orders";

  constructor(private http: HttpClient) {
    this.logSubject
      .pipe(
        debounceTime(5000),
        switchMap(() => {
          const dateStr = `-----${format(new Date(), "dd/MM/yyyy HH:mm")}-----`;
          return forkJoin(
            Object.entries(this.logMap).map(([orderId, logs]) =>
              this.http.post(`${this.apiUrl}/actions/log`, {
                orderId,
                logs: [dateStr, ...logs, dateStr],
              }),
            ),
          );
        }),
      )
      .subscribe(() => {
        this.logMap = {};
      });
  }

  getAll() {
    return this.http.get<IOrder[]>(this.apiUrl);
  }

  fetchAll() {
    return this.count().pipe(
      switchMap(({ count }) => {
        const pages = Math.ceil(count / 500);
        return forkJoin(range(pages).map((page) => this.findAll(page + 1))).pipe(
          map((orders) => orderBy(orders.flat(), ["date"], ["desc"])),
        );
      }),
    );
  }

  //not all field are populated
  findAll(page: number) {
    return this.http.put<IOrder[]>(this.apiUrl, { page });
  }

  getOne(orderId: string) {
    return this.http.get<IOrder>(`${this.apiUrl}/${orderId}`);
  }

  create(orderInput: Partial<OrderInput>) {
    return this.http.post<IOrder>(this.apiUrl, orderInput);
  }

  update(orderId: string, orderInput: Partial<OrderInput>) {
    return this.http.put<IOrder>(`${this.apiUrl}/${orderId}`, orderInput);
  }

  delete(orderId: string, isCancelled: boolean = false, isOnHold: boolean = false) {
    return this.http.delete<IOrder>(`${this.apiUrl}/${orderId}?isCancelled=${isCancelled}&isOnHold=${isOnHold}`);
  }

  restore(orderId: string) {
    return this.http.patch<IOrder>(`${this.apiUrl}/${orderId}`, {});
  }

  generateReference(params: { type: EOrderType; subType: EOrderSubType; source: string; destination: string }) {
    return this.http.post<{ reference: string }>(`${this.apiUrl}/actions/reference`, params);
  }

  getOptions(query?: string) {
    return this.http.post<Option[]>(`${this.apiUrl}/actions/options`, { query });
  }

  getOrderInput(orderId: string, params: { edit?: boolean; duplicate?: boolean } = { edit: false, duplicate: false }) {
    return this.http.post<OrderInput>(`${this.apiUrl}/actions/order-input`, { orderId, ...params }).pipe(
      map((orderInput) => {
        const { orderContractGeneral } = orderInput;
        return {
          ...orderInput,
          ...(orderContractGeneral?.targetDate && {
            orderContractGeneral: {
              ...orderContractGeneral,
              targetDate: parseISO(orderContractGeneral.targetDate as unknown as string),
            },
          }),
        };
      }),
    );
  }

  beforeOrderInput(
    orderId: string,
    params: { edit?: boolean; duplicate?: boolean } = { edit: false, duplicate: false },
  ) {
    return this.http.post<OrderInput>(`${this.apiUrl}/actions/before-order-input`, { orderId, ...params });
  }

  getCosts(orderId: string) {
    return this.http.post<IOrderStatCosts>(`${this.apiUrl}/actions/costs`, { orderId });
  }

  getStats(orderId: string) {
    return this.http.post<IOrderStat>(`${this.apiUrl}/actions/stats`, { orderId });
  }

  getItemStats(orderId: string) {
    return this.http.post<IOrderItemStat[]>(`${this.apiUrl}/actions/item-stats`, { orderId });
  }

  updateReference(orderId: string, reference: string) {
    return this.http.post<{ order: IOrder; milestones: IMilestone[] }>(`${this.apiUrl}/actions/update-reference`, {
      orderId,
      reference,
    });
  }

  updateExternalReference(orderId: string, externalReference: string) {
    return this.http.post<IOrder>(`${this.apiUrl}/actions/update-external-reference`, { orderId, externalReference });
  }

  updateComment(orderId: string, comment: string) {
    return this.http.post<IOrder>(`${this.apiUrl}/actions/update-comment`, { orderId, comment });
  }

  updateTargetDate(orderId: string, targetDate: Date, comment: string) {
    return this.http.post<{
      order: IOrder;
      milestones: IMilestone[];
    }>(`${this.apiUrl}/actions/update-target-date`, {
      orderId,
      targetDate,
      comment,
    });
  }

  minTargetDate(orderInput: Partial<OrderInput>, orderId?: string) {
    return this.http.post<string>(`${this.apiUrl}/actions/min-target-date`, { orderId, ...orderInput });
  }

  count() {
    return this.http.post<{ count: number }>(`${this.apiUrl}/actions/count`, {});
  }

  log(orderId: string, logs: any | any[]) {
    if (!orderId) return;
    if (!this.logMap[orderId]) {
      this.logMap[orderId] = [];
    }
    this.logMap[orderId].push(...(isArray(logs) ? logs : [logs]));
    this.logSubject.next();
  }

  overview(orderId: string) {
    return this.http.post<IDocument>(`${this.apiUrl}/actions/overview`, { orderId });
  }
}
