import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ILog, LogFilter } from "app/_shared/types/models/log";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class LogService {
  apiUrl: string = environment.api + "api/logs";

  constructor(private http: HttpClient) {}

  search(cursor = 0, filter?: LogFilter) {
    return this.http.post<ILog[]>(`${this.apiUrl}/actions/search`, { cursor, ...filter });
  }
}
