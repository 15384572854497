import { Injectable } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { AppStore } from "app/_shared/stores/app.store";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    private notificationService: NzNotificationService,
    private appStore: AppStore,
  ) {}

  error(err: string) {
    if (this.appStore.hasError()) return;
    this.notificationService.error(err, "", {
      nzStyle: { color: "white" },
    });
  }
}
