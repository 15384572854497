import { GridState } from "ag-grid-community";
import { ExternalFilter, TableName, datatables } from "app/_shared/utils/ag-grid.utils";

export interface TableState {
  id: TableName;
  gridState: GridState;
  quickFilter?: string;
  externalFilter?: ExternalFilter;
  rangeFilter?: string[];
  reset: "FILTERS" | "COLUMNS";
  export: "EXCEL" | "CSV";
}

export interface ITable {
  _id: string;
  user: string;
  tables: TableState[];
  createdAt: Date;
  updatedAt: Date;
}

export const filterTableState = (table: TableState) =>
  table?.id && (datatables.includes(table.id as any) || table.id.startsWith("inventory-"));
