import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ISharepointSync } from "app/_shared/types/models/sharepoint-sync";
import { environment } from "environments/environment";
import { Observable, of, switchMap, timer } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharepointSyncService {
  apiUrl: string = environment.api + "api/sharepoint-sync";

  constructor(private http: HttpClient) {}

  getOne(): Observable<ISharepointSync> {
    return this.http
      .get<ISharepointSync>(this.apiUrl)
      .pipe(switchMap((data) => (!data?.isProcessing ? of(data) : timer(3000).pipe(switchMap(() => this.getOne())))));
  }

  create() {
    return this.http.post<ISharepointSync>(this.apiUrl, {});
  }
}
