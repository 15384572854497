import { find } from "lodash";

export interface ICountry {
  name: string;
  alpha2: string;
  alpha3: string;
  continent: string;
  region?: string;
  subRegion?: string;
}

export const getNameByAlpha3 = (alpha3: string) => {
  const country = find(countries, ["alpha3", alpha3]);
  return country ? country.name : "-";
};

export const countries: ICountry[] = [
  {
    name: "Afghanistan",
    alpha2: "AF",
    alpha3: "AFG",
    continent: "Asia",
    subRegion: "Southern Asia",
  },
  {
    name: "Albania",
    alpha2: "AL",
    alpha3: "ALB",
    continent: "Europe",
    region: "Europe",
    subRegion: "Southern Europe",
  },
  {
    name: "Algeria",
    alpha2: "DZ",
    alpha3: "DZA",
    continent: "Africa",
    subRegion: "Northern Africa",
  },
  {
    name: "Angola",
    alpha2: "AO",
    alpha3: "AGO",
    continent: "Africa",
    subRegion: "Middle Africa",
  },
  {
    name: "Argentina",
    alpha2: "AR",
    alpha3: "ARG",
    continent: "Americas",
    region: "Latam",
    subRegion: "South America",
  },
  {
    name: "Armenia",
    alpha2: "AM",
    alpha3: "ARM",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Australia",
    alpha2: "AU",
    alpha3: "AUS",
    continent: "Oceania",
    subRegion: "Australia and New Zealand",
  },
  {
    name: "Austria",
    alpha2: "AT",
    alpha3: "AUT",
    continent: "Europe",
    region: "Europe",
    subRegion: "Western Europe",
  },
  {
    name: "Azerbaijan",
    alpha2: "AZ",
    alpha3: "AZE",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "The Bahamas",
    alpha2: "BS",
    alpha3: "BHS",
    continent: "Americas",
    region: "Latam",
    subRegion: "Caribbean",
  },
  {
    name: "Bahrain",
    alpha2: "BH",
    alpha3: "BHR",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Bangladesh",
    alpha2: "BD",
    alpha3: "BGD",
    continent: "Asia",
    subRegion: "Southern Asia",
  },
  {
    name: "Barbados",
    alpha2: "BB",
    alpha3: "BRB",
    continent: "Americas",
    region: "Latam",
    subRegion: "Caribbean",
  },
  {
    name: "Belarus",
    alpha2: "BY",
    alpha3: "BLR",
    continent: "Europe",
    region: "Europe",
    subRegion: "Eastern Europe",
  },
  {
    name: "Belgium",
    alpha2: "BE",
    alpha3: "BEL",
    continent: "Europe",
    region: "Europe",
    subRegion: "Western Europe",
  },
  {
    name: "Belize",
    alpha2: "BZ",
    alpha3: "BLZ",
    continent: "Americas",
    region: "Latam",
    subRegion: "Central America",
  },
  {
    name: "Benin",
    alpha2: "BJ",
    alpha3: "BEN",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Bermuda",
    alpha2: "BM",
    alpha3: "BMU",
    continent: "Americas",
    subRegion: "Northern America",
  },
  {
    name: "Bolivia",
    alpha2: "BO",
    alpha3: "BOL",
    continent: "Americas",
    region: "Latam",
    subRegion: "South America",
  },
  {
    name: "Bosnia and Herzegovina",
    alpha2: "BA",
    alpha3: "BIH",
    continent: "Europe",
    region: "Europe",
    subRegion: "Southern Europe",
  },
  {
    name: "Botswana",
    alpha2: "BW",
    alpha3: "BWA",
    continent: "Africa",
    subRegion: "Southern Africa",
  },
  {
    name: "Brazil",
    alpha2: "BR",
    alpha3: "BRA",
    continent: "Americas",
    region: "Latam",
    subRegion: "South America",
  },
  {
    name: "Brunei",
    alpha2: "BN",
    alpha3: "BRN",
    continent: "Asia",
    subRegion: "South-Eastern Asia",
  },
  {
    name: "Bulgaria",
    alpha2: "BG",
    alpha3: "BGR",
    continent: "Europe",
    region: "Europe",
    subRegion: "Eastern Europe",
  },
  {
    name: "Burkina Faso",
    alpha2: "BF",
    alpha3: "BFA",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Burundi",
    alpha2: "BI",
    alpha3: "BDI",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "Cambodia",
    alpha2: "KH",
    alpha3: "KHM",
    continent: "Asia",
    subRegion: "South-Eastern Asia",
  },
  {
    name: "Cameroon",
    alpha2: "CM",
    alpha3: "CMR",
    continent: "Africa",
    subRegion: "Middle Africa",
  },
  {
    name: "Canada",
    alpha2: "CA",
    alpha3: "CAN",
    continent: "Americas",
    subRegion: "Northern America",
  },
  {
    name: "Cape Verde",
    alpha2: "CV",
    alpha3: "CPV",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Central African Republic",
    alpha2: "CF",
    alpha3: "CAF",
    continent: "Africa",
    subRegion: "Middle Africa",
  },
  {
    name: "Chad",
    alpha2: "TD",
    alpha3: "TCD",
    continent: "Africa",
    subRegion: "Middle Africa",
  },
  {
    name: "Chile",
    alpha2: "CL",
    alpha3: "CHL",
    continent: "Americas",
    region: "Latam",
    subRegion: "South America",
  },
  {
    name: "China",
    alpha2: "CN",
    alpha3: "CHN",
    continent: "Asia",
    subRegion: "Eastern Asia",
  },
  {
    name: "Colombia",
    alpha2: "CO",
    alpha3: "COL",
    continent: "Americas",
    region: "Latam",
    subRegion: "South America",
  },
  {
    name: "Comoros",
    alpha2: "KM",
    alpha3: "COM",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "Republic of the Congo",
    alpha2: "CG",
    alpha3: "COG",
    continent: "Africa",
    subRegion: "Middle Africa",
  },
  {
    name: "Democratic Republic of the Congo",
    alpha2: "CD",
    alpha3: "COD",
    continent: "Africa",
    subRegion: "Middle Africa",
  },
  {
    name: "Costa Rica",
    alpha2: "CR",
    alpha3: "CRI",
    continent: "Americas",
    region: "Latam",
    subRegion: "Central America",
  },
  {
    name: "Ivory Coast",
    alpha2: "CI",
    alpha3: "CIV",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Croatia",
    alpha2: "HR",
    alpha3: "HRV",
    continent: "Europe",
    region: "Europe",
    subRegion: "Southern Europe",
  },
  {
    name: "Cuba",
    alpha2: "CU",
    alpha3: "CUB",
    continent: "Americas",
    region: "Latam",
    subRegion: "Caribbean",
  },
  {
    name: "Cyprus",
    alpha2: "CY",
    alpha3: "CYP",
    continent: "Europe",
    region: "Europe",
    subRegion: "Southern Europe",
  },
  {
    name: "Czech Republic",
    alpha2: "CZ",
    alpha3: "CZE",
    continent: "Europe",
    region: "Europe",
    subRegion: "Eastern Europe",
  },
  {
    name: "Denmark",
    alpha2: "DK",
    alpha3: "DNK",
    continent: "Europe",
    region: "Europe",
    subRegion: "Northern Europe",
  },
  {
    name: "Djibouti",
    alpha2: "DJ",
    alpha3: "DJI",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "Dominica",
    alpha2: "DM",
    alpha3: "DMA",
    continent: "Americas",
    region: "Latam",
    subRegion: "Caribbean",
  },
  {
    name: "Dominican Republic",
    alpha2: "DO",
    alpha3: "DOM",
    continent: "Americas",
    region: "Latam",
    subRegion: "Caribbean",
  },
  {
    name: "Ecuador",
    alpha2: "EC",
    alpha3: "ECU",
    continent: "Americas",
    region: "Latam",
    subRegion: "South America",
  },
  {
    name: "Egypt",
    alpha2: "EG",
    alpha3: "EGY",
    continent: "Africa",
    subRegion: "Northern Africa",
  },
  {
    name: "El Salvador",
    alpha2: "SV",
    alpha3: "SLV",
    continent: "Americas",
    region: "Latam",
    subRegion: "Central America",
  },
  {
    name: "Equatorial Guinea",
    alpha2: "GQ",
    alpha3: "GNQ",
    continent: "Africa",
    subRegion: "Middle Africa",
  },
  {
    name: "Eritrea",
    alpha2: "ER",
    alpha3: "ERI",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "Estonia",
    alpha2: "EE",
    alpha3: "EST",
    continent: "Europe",
    region: "Europe",
    subRegion: "Northern Europe",
  },
  {
    name: "Ethiopia",
    alpha2: "ET",
    alpha3: "ETH",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "Finland",
    alpha2: "FI",
    alpha3: "FIN",
    continent: "Europe",
    region: "Europe",
    subRegion: "Northern Europe",
  },
  {
    name: "France",
    alpha2: "FR",
    alpha3: "FRA",
    continent: "Europe",
    region: "Europe",
    subRegion: "Western Europe",
  },
  {
    name: "Gabon",
    alpha2: "GA",
    alpha3: "GAB",
    continent: "Africa",
    subRegion: "Middle Africa",
  },
  {
    name: "The Gambia",
    alpha2: "GM",
    alpha3: "GMB",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Georgia",
    alpha2: "GE",
    alpha3: "GEO",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Germany",
    alpha2: "DE",
    alpha3: "DEU",
    continent: "Europe",
    region: "Europe",
    subRegion: "Western Europe",
  },
  {
    name: "Ghana",
    alpha2: "GH",
    alpha3: "GHA",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Greece",
    alpha2: "GR",
    alpha3: "GRC",
    continent: "Europe",
    region: "Europe",
    subRegion: "Southern Europe",
  },
  {
    name: "Grenada",
    alpha2: "GD",
    alpha3: "GRD",
    continent: "Americas",
    region: "Latam",
    subRegion: "Caribbean",
  },
  {
    name: "Guadeloupe",
    alpha2: "GP",
    alpha3: "GLP",
    continent: "Americas",
    region: "Latam",
    subRegion: "Caribbean",
  },
  {
    name: "Guatemala",
    alpha2: "GT",
    alpha3: "GTM",
    continent: "Americas",
    region: "Latam",
    subRegion: "Central America",
  },
  {
    name: "Guinea",
    alpha2: "GN",
    alpha3: "GIN",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Guinea-Bissau",
    alpha2: "GW",
    alpha3: "GNB",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Guyana",
    alpha2: "GY",
    alpha3: "GUY",
    continent: "Americas",
    region: "Latam",
    subRegion: "South America",
  },
  {
    name: "Haiti",
    alpha2: "HT",
    alpha3: "HTI",
    continent: "Americas",
    region: "Latam",
    subRegion: "Caribbean",
  },
  {
    name: "Honduras",
    alpha2: "HN",
    alpha3: "HND",
    continent: "Americas",
    region: "Latam",
    subRegion: "Central America",
  },
  {
    name: "Hong Kong",
    alpha2: "HK",
    alpha3: "HKG",
    continent: "Asia",
    subRegion: "Eastern Asia",
  },
  {
    name: "Hungary",
    alpha2: "HU",
    alpha3: "HUN",
    continent: "Europe",
    region: "Europe",
    subRegion: "Eastern Europe",
  },
  {
    name: "Iceland",
    alpha2: "IS",
    alpha3: "ISL",
    continent: "Europe",
    region: "Europe",
    subRegion: "Northern Europe",
  },
  {
    name: "India",
    alpha2: "IN",
    alpha3: "IND",
    continent: "Asia",
    subRegion: "Southern Asia",
  },
  {
    name: "Indonesia",
    alpha2: "ID",
    alpha3: "IDN",
    continent: "Asia",
    subRegion: "South-Eastern Asia",
  },
  {
    name: "Iran",
    alpha2: "IR",
    alpha3: "IRN",
    continent: "Asia",
    subRegion: "Southern Asia",
  },
  {
    name: "Iraq",
    alpha2: "IQ",
    alpha3: "IRQ",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Ireland",
    alpha2: "IE",
    alpha3: "IRL",
    continent: "Europe",
    region: "Europe",
    subRegion: "Northern Europe",
  },
  {
    name: "Israel",
    alpha2: "IL",
    alpha3: "ISR",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Italy",
    alpha2: "IT",
    alpha3: "ITA",
    continent: "Europe",
    region: "Europe",
    subRegion: "Southern Europe",
  },
  {
    name: "Jamaica",
    alpha2: "JM",
    alpha3: "JAM",
    continent: "Americas",
    region: "Latam",
    subRegion: "Caribbean",
  },
  {
    name: "Japan",
    alpha2: "JP",
    alpha3: "JPN",
    continent: "Asia",
    subRegion: "Eastern Asia",
  },
  {
    name: "Jordan",
    alpha2: "JO",
    alpha3: "JOR",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Kazakhstan",
    alpha2: "KZ",
    alpha3: "KAZ",
    continent: "Asia",
    subRegion: "Central Asia",
  },
  {
    name: "Kenya",
    alpha2: "KE",
    alpha3: "KEN",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "North Korea",
    alpha2: "KP",
    alpha3: "PRK",
    continent: "Asia",
    subRegion: "Eastern Asia",
  },
  {
    name: "South Korea",
    alpha2: "KR",
    alpha3: "KOR",
    continent: "Asia",
    subRegion: "Eastern Asia",
  },
  {
    name: "Kuwait",
    alpha2: "KW",
    alpha3: "KWT",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Kyrgyzstan",
    alpha2: "KG",
    alpha3: "KGZ",
    continent: "Asia",
    subRegion: "Central Asia",
  },
  {
    name: "Laos",
    alpha2: "LA",
    alpha3: "LAO",
    continent: "Asia",
    subRegion: "South-Eastern Asia",
  },
  {
    name: "Latvia",
    alpha2: "LV",
    alpha3: "LVA",
    continent: "Europe",
    region: "Europe",
    subRegion: "Northern Europe",
  },
  {
    name: "Lebanon",
    alpha2: "LB",
    alpha3: "LBN",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Lesotho",
    alpha2: "LS",
    alpha3: "LSO",
    continent: "Africa",
    subRegion: "Southern Africa",
  },
  {
    name: "Liberia",
    alpha2: "LR",
    alpha3: "LBR",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Libya",
    alpha2: "LY",
    alpha3: "LBY",
    continent: "Africa",
    subRegion: "Northern Africa",
  },
  {
    name: "Lithuania",
    alpha2: "LT",
    alpha3: "LTU",
    continent: "Europe",
    region: "Europe",
    subRegion: "Northern Europe",
  },
  {
    name: "Macau",
    alpha2: "MO",
    alpha3: "MAC",
    continent: "Asia",
    subRegion: "Eastern Asia",
  },
  {
    name: "Republic of Macedonia",
    alpha2: "MK",
    alpha3: "MKD",
    continent: "Europe",
    region: "Europe",
    subRegion: "Southern Europe",
  },
  {
    name: "Madagascar",
    alpha2: "MG",
    alpha3: "MDG",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "Malawi",
    alpha2: "MW",
    alpha3: "MWI",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "Malaysia",
    alpha2: "MY",
    alpha3: "MYS",
    continent: "Asia",
    subRegion: "South-Eastern Asia",
  },
  {
    name: "Mali",
    alpha2: "ML",
    alpha3: "MLI",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Malta",
    alpha2: "MT",
    alpha3: "MLT",
    continent: "Europe",
    region: "Europe",
    subRegion: "Southern Europe",
  },
  {
    name: "Martinique",
    alpha2: "MQ",
    alpha3: "MTQ",
    continent: "Americas",
    region: "Latam",
    subRegion: "Caribbean",
  },
  {
    name: "Mauritania",
    alpha2: "MR",
    alpha3: "MRT",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Mexico",
    alpha2: "MX",
    alpha3: "MEX",
    continent: "Americas",
    region: "Latam",
    subRegion: "Central America",
  },
  {
    name: "Moldova",
    alpha2: "MD",
    alpha3: "MDA",
    continent: "Europe",
    region: "Europe",
    subRegion: "Eastern Europe",
  },
  {
    name: "Mongolia",
    alpha2: "MN",
    alpha3: "MNG",
    continent: "Asia",
    subRegion: "Eastern Asia",
  },
  {
    name: "Morocco",
    alpha2: "MA",
    alpha3: "MAR",
    continent: "Africa",
    subRegion: "Northern Africa",
  },
  {
    name: "Mozambique",
    alpha2: "MZ",
    alpha3: "MOZ",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "Namibia",
    alpha2: "NA",
    alpha3: "NAM",
    continent: "Africa",
    subRegion: "Southern Africa",
  },
  {
    name: "Nepal",
    alpha2: "NP",
    alpha3: "NPL",
    continent: "Asia",
    subRegion: "Southern Asia",
  },
  {
    name: "Netherlands",
    alpha2: "NL",
    alpha3: "NLD",
    continent: "Europe",
    region: "Europe",
    subRegion: "Western Europe",
  },
  {
    name: "New Zealand",
    alpha2: "NZ",
    alpha3: "NZL",
    continent: "Oceania",
    subRegion: "Australia and New Zealand",
  },
  {
    name: "Nicaragua",
    alpha2: "NI",
    alpha3: "NIC",
    continent: "Americas",
    region: "Latam",
    subRegion: "Central America",
  },
  {
    name: "Niger",
    alpha2: "NE",
    alpha3: "NER",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Nigeria",
    alpha2: "NG",
    alpha3: "NGA",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Norway",
    alpha2: "NO",
    alpha3: "NOR",
    continent: "Europe",
    region: "Europe",
    subRegion: "Northern Europe",
  },
  {
    name: "Oman",
    alpha2: "OM",
    alpha3: "OMN",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Pakistan",
    alpha2: "PK",
    alpha3: "PAK",
    continent: "Asia",
    subRegion: "Southern Asia",
  },
  {
    name: "Panama",
    alpha2: "PA",
    alpha3: "PAN",
    continent: "Americas",
    region: "Latam",
    subRegion: "Central America",
  },
  {
    name: "Paraguay",
    alpha2: "PY",
    alpha3: "PRY",
    continent: "Americas",
    region: "Latam",
    subRegion: "South America",
  },
  {
    name: "Peru",
    alpha2: "PE",
    alpha3: "PER",
    continent: "Americas",
    region: "Latam",
    subRegion: "South America",
  },
  {
    name: "Philippines",
    alpha2: "PH",
    alpha3: "PHL",
    continent: "Asia",
    subRegion: "South-Eastern Asia",
  },
  {
    name: "Poland",
    alpha2: "PL",
    alpha3: "POL",
    continent: "Europe",
    region: "Europe",
    subRegion: "Eastern Europe",
  },
  {
    name: "Portugal",
    alpha2: "PT",
    alpha3: "PRT",
    continent: "Europe",
    region: "Europe",
    subRegion: "Southern Europe",
  },
  {
    name: "Puerto Rico",
    alpha2: "PR",
    alpha3: "PRI",
    continent: "Americas",
    region: "Latam",
    subRegion: "Caribbean",
  },
  {
    name: "Qatar",
    alpha2: "QA",
    alpha3: "QAT",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Romania",
    alpha2: "RO",
    alpha3: "ROU",
    continent: "Europe",
    region: "Europe",
    subRegion: "Eastern Europe",
  },
  {
    name: "Russia",
    alpha2: "RU",
    alpha3: "RUS",
    continent: "Europe",
    region: "Europe",
    subRegion: "Eastern Europe",
  },
  {
    name: "Rwanda",
    alpha2: "RW",
    alpha3: "RWA",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "Saint Helena",
    alpha2: "SH",
    alpha3: "SHN",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "San Marino",
    alpha2: "SM",
    alpha3: "SMR",
    continent: "Europe",
    region: "Europe",
    subRegion: "Southern Europe",
  },
  {
    name: "São Tomé and Príncipe",
    alpha2: "ST",
    alpha3: "STP",
    continent: "Africa",
    subRegion: "Middle Africa",
  },
  {
    name: "Saudi Arabia",
    alpha2: "SA",
    alpha3: "SAU",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Senegal",
    alpha2: "SN",
    alpha3: "SEN",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Serbia",
    alpha2: "RS",
    alpha3: "SRB",
    continent: "Europe",
    region: "Europe",
    subRegion: "Eastern Europe",
  },
  {
    name: "Sierra Leone",
    alpha2: "SL",
    alpha3: "SLE",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Singapore",
    alpha2: "SG",
    alpha3: "SGP",
    continent: "Asia",
    subRegion: "South-Eastern Asia",
  },
  {
    name: "Slovakia",
    alpha2: "SK",
    alpha3: "SVK",
    continent: "Europe",
    region: "Europe",
    subRegion: "Eastern Europe",
  },
  {
    name: "Slovenia",
    alpha2: "SI",
    alpha3: "SVN",
    continent: "Europe",
    region: "Europe",
    subRegion: "Southern Europe",
  },
  {
    name: "Somalia",
    alpha2: "SO",
    alpha3: "SOM",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "South Africa",
    alpha2: "ZA",
    alpha3: "ZAF",
    continent: "Africa",
    subRegion: "Southern Africa",
  },
  {
    name: "South Sudan",
    alpha2: "SS",
    alpha3: "SSD",
    continent: "Africa",
    subRegion: "Middle Africa",
  },
  {
    name: "Spain",
    alpha2: "ES",
    alpha3: "ESP",
    continent: "Europe",
    region: "Europe",
    subRegion: "Southern Europe",
  },
  {
    name: "Sri Lanka",
    alpha2: "LK",
    alpha3: "LKA",
    continent: "Asia",
    subRegion: "Southern Asia",
  },
  {
    name: "Sudan",
    alpha2: "SD",
    alpha3: "SDN",
    continent: "Africa",
    subRegion: "Northern Africa",
  },
  {
    name: "Suriname",
    alpha2: "SR",
    alpha3: "SUR",
    continent: "Americas",
    region: "Latam",
    subRegion: "South America",
  },
  {
    name: "Swaziland",
    alpha2: "SZ",
    alpha3: "SWZ",
    continent: "Africa",
    subRegion: "Southern Africa",
  },
  {
    name: "Sweden",
    alpha2: "SE",
    alpha3: "SWE",
    continent: "Europe",
    region: "Europe",
    subRegion: "Northern Europe",
  },
  {
    name: "Switzerland",
    alpha2: "CH",
    alpha3: "CHE",
    continent: "Europe",
    region: "Europe",
    subRegion: "Western Europe",
  },
  {
    name: "Syria",
    alpha2: "SY",
    alpha3: "SYR",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Taiwan",
    alpha2: "TW",
    alpha3: "TWN",
    continent: "Asia",
    subRegion: "Eastern Asia",
  },
  {
    name: "Tajikistan",
    alpha2: "TJ",
    alpha3: "TJK",
    continent: "Asia",
    subRegion: "Central Asia",
  },
  {
    name: "Tanzania",
    alpha2: "TZ",
    alpha3: "TZA",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "Thailand",
    alpha2: "TH",
    alpha3: "THA",
    continent: "Asia",
    subRegion: "South-Eastern Asia",
  },
  {
    name: "East Timor",
    alpha2: "TL",
    alpha3: "TLS",
    continent: "Asia",
    subRegion: "South-Eastern Asia",
  },
  {
    name: "Togo",
    alpha2: "TG",
    alpha3: "TGO",
    continent: "Africa",
    subRegion: "Western Africa",
  },
  {
    name: "Tunisia",
    alpha2: "TN",
    alpha3: "TUN",
    continent: "Africa",
    subRegion: "Northern Africa",
  },
  {
    name: "Turkey",
    alpha2: "TR",
    alpha3: "TUR",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Turkmenistan",
    alpha2: "TM",
    alpha3: "TKM",
    continent: "Asia",
    subRegion: "Central Asia",
  },
  {
    name: "Uganda",
    alpha2: "UG",
    alpha3: "UGA",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "Ukraine",
    alpha2: "UA",
    alpha3: "UKR",
    continent: "Europe",
    region: "Europe",
    subRegion: "Eastern Europe",
  },
  {
    name: "United Arab Emirates",
    alpha2: "AE",
    alpha3: "ARE",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "United Kingdom",
    alpha2: "GB",
    alpha3: "GBR",
    continent: "Europe",
    region: "Europe",
    subRegion: "Northern Europe",
  },
  {
    name: "United States",
    alpha2: "US",
    alpha3: "USA",
    continent: "Americas",
    subRegion: "Northern America",
  },
  {
    name: "Uruguay",
    alpha2: "UY",
    alpha3: "URY",
    continent: "Americas",
    region: "Latam",
    subRegion: "South America",
  },
  {
    name: "Uzbekistan",
    alpha2: "UZ",
    alpha3: "UZB",
    continent: "Asia",
    subRegion: "Central Asia",
  },
  {
    name: "Venezuela",
    alpha2: "VE",
    alpha3: "VEN",
    continent: "Americas",
    region: "Latam",
    subRegion: "South America",
  },
  {
    name: "Vietnam",
    alpha2: "VN",
    alpha3: "VNM",
    continent: "Asia",
    subRegion: "South-Eastern Asia",
  },
  {
    name: "Yemen",
    alpha2: "YE",
    alpha3: "YEM",
    continent: "Asia",
    subRegion: "Western Asia",
  },
  {
    name: "Zambia",
    alpha2: "ZM",
    alpha3: "ZMB",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
  {
    name: "Zimbabwe",
    alpha2: "ZW",
    alpha3: "ZWE",
    continent: "Africa",
    subRegion: "Eastern Africa",
  },
];
