import { Injectable } from "@angular/core";
import { createStore } from "@ngneat/elf";
import {
  addEntities,
  deleteEntities,
  selectAllEntities,
  setEntities,
  upsertEntities,
  withEntities,
} from "@ngneat/elf-entities";
import { IOrder } from "app/_shared/types/models/order";
import { take } from "rxjs";

const store = createStore({ name: "orders" }, withEntities<IOrder, "_id">({ idKey: "_id" }));

@Injectable({ providedIn: "root" })
export class OrderStore {
  constructor() {}

  findAll$ = store.pipe(selectAllEntities(), take(1));

  setEntities(state: IOrder[]) {
    store.update(setEntities(state));
  }

  addEntities(state: IOrder[]) {
    store.update(addEntities(state, { prepend: true }));
  }

  updateEntities(state: IOrder[]) {
    store.update(upsertEntities(state));
  }

  removeEntities(ids: string[]) {
    store.update(deleteEntities(ids));
  }

  destroy() {
    store.reset();
  }
}
