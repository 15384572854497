import { Component, inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "app/_shared/services/user.service";
import { IUser, UserInputForm } from "app/_shared/types/models/user";
import { UserEditComponentData } from "app/users/users/user-edit/user-edit.component";
import { NzMessageService } from "ng-zorro-antd/message";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { finalize } from "rxjs";

export interface ProfileEditComponentData {
  user: IUser;
}

@Component({
  selector: "app-profile-edit",
  templateUrl: "./profile-edit.component.html",
  styles: [],
})
export class ProfileEditComponent implements OnInit {
  readonly data: UserEditComponentData = inject(NZ_MODAL_DATA);

  editUserForm = this.fb.group({
    firstName: this.fb.nonNullable.control<string>(null, [Validators.required]),
    lastName: this.fb.nonNullable.control<string>(null, [Validators.required]),
    phones: this.fb.array([]),
    skype: this.fb.control<string>(null, []),
  });

  loading = false;

  constructor(
    private modal: NzModalRef,
    private messageService: NzMessageService,
    private fb: FormBuilder,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    if (this.data.user) {
      this.data.user.phones.forEach(() => this.addPhone());
      this.editUserForm.patchValue({
        firstName: this.data.user.firstName,
        lastName: this.data.user.lastName,
        phones: this.data.user.phones.map(({ type, number }) => ({ type, number })),
        skype: this.data.user.skype,
      });
      if (!this.data.user.phones.length) this.addPhone();
    }
  }

  destroyModal(data?: IUser): void {
    this.modal.destroy(data);
  }

  edit() {
    Object.values(this.editUserForm.controls).forEach((control) => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity();
      }
    });
    if (!this.editUserForm.valid) return;
    const value = this.editUserForm.value as UserInputForm;
    this.loading = true;
    this.userService
      .update(this.data.user._id, value)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (data: IUser) => {
          this.messageService.success("Profile updated successfully.");
          this.destroyModal(data);
        },
      });
  }

  get phones(): FormArray {
    return this.editUserForm.get("phones") as FormArray;
  }

  addPhone(): void {
    this.phones.push(
      this.fb.group({
        type: this.fb.control<string>("professional"),
        number: this.fb.control<string>(null),
      }),
    );
  }

  removePhone(index: number): void {
    if (this.phones.length === 1) return;
    this.phones.removeAt(index);
  }
}
