import { Component, Input } from "@angular/core";

//todo remove divider from app-text and work with nz-space split
@Component({
  selector: "app-text",
  template: `
    <div class="w-full flex justify-between items-center min-h-[20px]">
      <div class="text-philippine-gray text-sm min-w-[120px]">
        {{ toUpperCase ? (title | uppercase) : (title | capitalize) }}
      </div>
      <div class="text-dark break-all whitespace-normal" [ngClass]="'text-' + size">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class TextComponent {
  @Input() title: string;
  @Input() toUpperCase: boolean = false;
  @Input() size: "sm" | "base" | "lg" | "xl" = "base";
}
