import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "environments/environment";
import { SearchResult } from "app/_shared/types/dashboard";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  apiUrl: string = environment.api + "api/search";

  constructor(private http: HttpClient) {}

  search(query: string) {
    return this.http.get<SearchResult[]>(`${this.apiUrl}?query=${query}`);
  }
}
