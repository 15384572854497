import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IProduct } from "app/_shared/types/models/product";

import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  apiUrl: string = environment.api + "api/products";

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<IProduct[]>(this.apiUrl);
  }

  getOne(productId: string) {
    return this.http.get<IProduct>(`${this.apiUrl}/${productId}`);
  }

  create(product: any) {
    return this.http.post<IProduct>(this.apiUrl, product);
  }

  update(productId: string, product: any) {
    return this.http.put<IProduct>(`${this.apiUrl}/${productId}`, product);
  }

  delete(productId: string) {
    return this.http.delete<IProduct>(`${this.apiUrl}/${productId}`);
  }
}
