import { Directive, ElementRef, Output, EventEmitter, HostListener } from "@angular/core";

@Directive({
  selector: "[clickOutside]",
})
export class ClickOutsideDirective {
  constructor(private _elementRef: ElementRef) {}

  @Output() clickedOutside = new EventEmitter<void>();

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event: any): void {
    if (!this._elementRef.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.clickedOutside.emit();
    }
  }
}
