import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { Component } from "@angular/core";
import { Router } from "@angular/router";

interface ILinkCellRendererParams {
  replacedValue: string;
  fctName: string;
  url: string;
  newTab: boolean;
  newTabForce: boolean;
  newTabText: string;
  newTabUrl: string;
  isDisabled: boolean;
}

@Component({
  selector: "ag-grid-custom-cell-link",
  template: `
    @if (disabled || !params?.data) {
      {{ value }}
    } @else {
      <div class="flex w-full justify-between items-center group/link">
        <span
          class="cursor-pointer group-hover/link:underline"
          [ngClass]="{ 'group-hover/link:w-[calc(100%-60px)] group-hover/link:truncate': newTab && !newTabForce }"
          (click)="goto($event)">
          {{ value }}</span
        >
        @if (newTab && !newTabForce) {
          <span
            class="px-2 absolute right-0 cursor-pointer hidden group/tab group-hover/link:inline-block hover:text-mantis-500"
            (click)="goto($event, true)">
            <span class="flex items-center">
              {{ newTabText }}
              <span class="group-hover/tab:translate-x-0.5" nz-icon nzType="right" nzTheme="outline"></span>
            </span>
          </span>
        }
      </div>
    }
  `,
})
export class AgGridCustomCellLinkComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  //in case of display value is different from params.value
  value: string;
  url: string;
  newTab: boolean;
  newTabForce: boolean;
  newTabText: string;
  newTabUrl: string;
  fctName: string;
  disabled: boolean;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams & ILinkCellRendererParams): void {
    this.setData(params);
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams & ILinkCellRendererParams): boolean {
    this.setData(params);
    return true;
  }

  setData(params: ICellRendererParams & ILinkCellRendererParams) {
    this.params = params;
    this.value = params.replacedValue ?? params.value;
    this.url = params.url;
    this.newTab = !!params.newTab;
    this.newTabForce = params.newTabForce;
    this.newTabText = params.newTabText ?? "New tab";
    this.newTabUrl = params.newTabUrl ?? params.url;
    this.fctName = params.fctName ?? "goto";
    this.disabled = params.isDisabled || !!params.data?.isDeleted;
  }

  async goto(event: MouseEvent, newTab: boolean = false): Promise<void> {
    if (this.disabled) {
      return;
    }
    if (this.url) {
      if (newTab || this.newTabForce || event?.ctrlKey || event?.metaKey) {
        window.open(this.newTabUrl, "_blank");
        return;
      }
      await this.router.navigate([this.url]);
    } else {
      this.params.context.component[this.fctName](this.params.data, newTab);
    }
  }
}
