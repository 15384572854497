import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { AppStore } from "app/_shared/stores/app.store";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(private router: Router, private appStore: AppStore) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.appStore.user()) {
      return this.router.navigate(["login"]);
    }
    return true;
  }
}
