<div class="my-2 mx-1 px-2 pt-2 pb-1 bg-lotion border radius-lg border-light-silver rounded-sm">
  <form nz-form nzLayout="vertical" [formGroup]="filterForm">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-control>
            <nz-input-group [nzSuffix]="inputClearTpl">
              <input type="text" nz-input formControlName="query" id="query" placeholder="Search.." />
            </nz-input-group>
            <ng-template #inputClearTpl>
              @if (!!filterForm.get("query").value) {
                <span nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" (click)="filterForm.get('query').setValue('')"></span>
              } @else {
                <span nz-icon nzType="search"></span>
              }
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="6">
        <nz-form-item>
          <nz-form-control>
            <nz-select formControlName="model" nzId="model" nzPlaceHolder="Entity.." nzShowSearch nzAllowClear>
              @for (model of models; track $index) {
                <nz-option [nzLabel]="model | startCase" [nzValue]="model" />
              }
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="6">
        <nz-form-item>
          <nz-form-control>
            <nz-select formControlName="user" nzId="user" nzPlaceHolder="User.." nzShowSearch nzAllowClear>
              @for (user of users$ | async; track user._id) {
                <nz-option nzCustomContent [nzValue]="user._id" [nzLabel]="user.displayName">
                  <app-user-avatar [user]="user" [size]="16" />
                  {{ user.displayName }}
                </nz-option>
              }
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</div>
