import { CommonModule, NgOptimizedImage } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgClickOutsideDirective, NgClickOutsideExcludeDirective } from "ng-click-outside2";

import {
  AgGridCustomCellActionComponent,
  AgGridCustomCellActionsComponent,
  AgGridCustomCellAvatarComponent,
  AgGridCustomCellBoolComponent,
  AgGridCustomCellLinkComponent,
  AgGridCustomCellEmailComponent,
  AgGridCustomCellTextListComponent,
  AgGridCustomInnerGroupActionComponent,
  AgGridCustomEditBoolComponent,
  AgGridCustomEditDateComponent,
} from "app/_shared/ag-grid";

import { AddressCreateComponent, AddressEditComponent } from "app/_shared/components/addresses";
import { ProfileEditComponent } from "app/_shared/components/profile-edit/profile-edit.component";
import { SharepointSyncComponent } from "app/_shared/components/sharepoint-sync/sharepoint-sync.component";
import {
  BoolComponent,
  DescriptionComponent,
  DisplayComponent,
  LoadingComponent,
  SearchModalComponent,
  SideBarComponent,
  HeaderComponent,
  TextAreaEditComponent,
  TextComponent,
  UserAvatarComponent,
  NoContentComponent,
} from "app/_shared/components/common";
import {
  DocumentCreateComponent,
  DocumentDetailsComponent,
  DocumentEditComponent,
  DocumentListComponent,
} from "app/_shared/components/documents";
import { EmailDetailsComponent, EmailEditComponent } from "app/_shared/components/emails";
import {
  LogDrawerComponent,
  LogDrawerEntityComponent,
  LogDrawerPropertiesComponent,
  LogFormComponent,
} from "app/_shared/components/log";
import {
  CancelRelatedDocumentSignsComponent,
  OrderCostUpsertComponent,
  SubOrderCostListComponent,
  OrderCreateCostListComponent,
  OrderEditCostListComponent,
} from "app/_shared/components/orders";
import {
  ExportButtonsComponent,
  ExternalFilterComponent,
  QuickFilterComponent,
  ResetTableComponent,
  TotalComponent,
} from "app/_shared/components/table";
import { MilestoneTemplateValueDateFormComponent } from "app/_shared/components/templates";
import { AgGridSetupModule } from "./ag-grid-setup.module";

import {
  AutofocusDirective,
  ClickOutsideDirective,
  EnterPressedDirective,
  EntityAsyncValidatorDirective,
  ObserveElementDirective,
  UppercaseDirective,
} from "./directives";

import { NgZorroModule } from "./ng-zorro.module";
import {
  AddressPipe,
  CapitalizePipe,
  ConcatPipe,
  InitialsPipe,
  PhonePipe,
  StartCasePipe,
  TextListPipe,
  UnderscorePipe,
} from "./pipes";
import { SearchComponent } from "app/_shared/components/search/search.component";

@NgModule({
  declarations: [
    //directives
    UppercaseDirective,
    AutofocusDirective,
    EntityAsyncValidatorDirective,
    ObserveElementDirective,
    ClickOutsideDirective,
    EnterPressedDirective,
    //pipes
    UnderscorePipe,
    InitialsPipe,
    TextListPipe,
    CapitalizePipe,
    ConcatPipe,
    AddressPipe,
    PhonePipe,
    StartCasePipe,
    //common
    SideBarComponent,
    HeaderComponent,
    LoadingComponent,
    UserAvatarComponent,
    SearchModalComponent,
    TextComponent,
    DescriptionComponent,
    DisplayComponent,
    TextAreaEditComponent,
    BoolComponent,
    NoContentComponent,
    //ag-grid
    AgGridCustomCellLinkComponent,
    AgGridCustomCellEmailComponent,
    AgGridCustomCellActionComponent,
    AgGridCustomCellActionsComponent,
    AgGridCustomCellAvatarComponent,
    AgGridCustomCellBoolComponent,
    AgGridCustomCellTextListComponent,
    AgGridCustomInnerGroupActionComponent,
    AgGridCustomEditBoolComponent,
    AgGridCustomEditDateComponent,
    //table
    ExternalFilterComponent,
    ResetTableComponent,
    QuickFilterComponent,
    ExportButtonsComponent,
    TotalComponent,
    //search
    SearchComponent,
    //log
    LogDrawerComponent,
    LogDrawerEntityComponent,
    LogDrawerPropertiesComponent,
    LogFormComponent,
    //profile
    ProfileEditComponent,
    //sharepoint
    SharepointSyncComponent,
    //address
    AddressCreateComponent,
    AddressEditComponent,
    //document
    DocumentListComponent,
    DocumentCreateComponent,
    DocumentEditComponent,
    DocumentDetailsComponent,
    //template
    MilestoneTemplateValueDateFormComponent,
    //order
    SubOrderCostListComponent,
    OrderCostUpsertComponent,
    CancelRelatedDocumentSignsComponent,
    OrderCreateCostListComponent,
    OrderEditCostListComponent,
    //email
    EmailDetailsComponent,
    EmailEditComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroModule,
    AgGridSetupModule,
    //directives
    UppercaseDirective,
    AutofocusDirective,
    EntityAsyncValidatorDirective,
    ObserveElementDirective,
    ClickOutsideDirective,
    EnterPressedDirective,
    //pipes
    UnderscorePipe,
    InitialsPipe,
    TextListPipe,
    CapitalizePipe,
    ConcatPipe,
    AddressPipe,
    PhonePipe,
    StartCasePipe,
    //common
    SideBarComponent,
    HeaderComponent,
    LoadingComponent,
    UserAvatarComponent,
    SearchModalComponent,
    TextComponent,
    DescriptionComponent,
    DisplayComponent,
    TextAreaEditComponent,
    BoolComponent,
    NoContentComponent,
    //ag-grid
    AgGridCustomCellLinkComponent,
    AgGridCustomCellEmailComponent,
    AgGridCustomCellActionComponent,
    AgGridCustomCellActionsComponent,
    AgGridCustomCellAvatarComponent,
    AgGridCustomCellBoolComponent,
    AgGridCustomCellTextListComponent,
    AgGridCustomInnerGroupActionComponent,
    AgGridCustomEditBoolComponent,
    AgGridCustomEditDateComponent,
    //table
    ExternalFilterComponent,
    ResetTableComponent,
    QuickFilterComponent,
    ExportButtonsComponent,
    TotalComponent,
    //search
    SearchComponent,
    //log
    LogDrawerComponent,
    LogDrawerEntityComponent,
    LogDrawerPropertiesComponent,
    LogFormComponent,
    //profile
    ProfileEditComponent,
    //sharepoint
    SharepointSyncComponent,
    //address
    AddressCreateComponent,
    AddressEditComponent,
    //document
    DocumentListComponent,
    DocumentCreateComponent,
    DocumentEditComponent,
    DocumentDetailsComponent,
    //template
    MilestoneTemplateValueDateFormComponent,
    //order
    SubOrderCostListComponent,
    OrderCostUpsertComponent,
    CancelRelatedDocumentSignsComponent,
    OrderCreateCostListComponent,
    OrderEditCostListComponent,
    //email
    EmailDetailsComponent,
    EmailEditComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroModule,
    AgGridSetupModule,
    NgOptimizedImage,
    NgClickOutsideDirective,
    NgClickOutsideExcludeDirective,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class SharedModule {}
