import { ICellEditorAngularComp } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-community";
import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
  selector: "app-ag-grid-custom-edit-bool",
  template: ` <nz-switch [(ngModel)]="value" /> `,
})
export class AgGridCustomEditBoolComponent implements ICellEditorAngularComp, AfterViewInit {
  private params!: ICellEditorParams;
  public value!: boolean;

  @ViewChild("input", { read: ViewContainerRef }) public input: ViewContainerRef;

  ngAfterViewInit() {
    // focus on the input
    setTimeout(() => this.input.element.nativeElement.focus());
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;

    this.value = !!this.params.value;
  }

  /* Component Editor Lifecycle methods */

  // the final value to send to the grid, on completion of editing
  getValue() {
    // this simple editor doubles any value entered into the input
    return this.value;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  }

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    return false;
  }
}
