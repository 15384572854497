<form nz-form nzLayout="vertical" [formGroup]="searchForm">
  <nz-form-item class="!mb-0">
    <nz-form-control>
      <nz-input-group
        [nzSuffix]="suffixIcon"
        nz-popover
        [nzPopoverContent]="contentTemplate"
        nzPopoverPlacement="bottom"
        [nzPopoverTrigger]="null"
        [nzPopoverVisible]="visible"
        [nzPopoverBackdrop]="true"
        [nzPopoverArrowPointAtCenter]="true">
        <input
          type="text"
          class="w-80 min-w-80"
          nz-input
          formControlName="query"
          placeholder="Search ..."
          id="searchInput"
          (click)="onClickInside()"
          [autofocus]="true" />
      </nz-input-group>
      <ng-template #suffixIcon>
        @if (loading) {
          <span nz-icon nzType="loading"></span>
        } @else {
          @if (query) {
            <span nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" (click)="searchForm.reset()"></span>
          } @else {
            <span nz-icon nzType="search"></span>
          }
        }
      </ng-template>
      <ng-template #contentTemplate>
        <div class="w-96 min-h-[150px] overflow-y-auto -my-2 -mx-3 bg-lotion" (clickOutside)="onClickOutside($event)">
          @if (!result.length || !query || query.length < 3) {
            <div class="h-full flex items-center justify-center m-3">
              <nz-empty [nzNotFoundContent]="loading ? 'loading ..' : 'Please input your search query (min 3 chars).'" />
            </div>
          } @else if (result.length) {
            <div class="my-2">
              @if (hasResults) {
                @for (res of result; track $index) {
                  @if (res.value.length) {
                    <span class="text-dark font-semibold text-lg px-2 h-7 flex items-end">{{ res.key | titlecase }}</span>
                    <ul class="list-none list-inside">
                      @for (val of res.value; track $index; let last = $last) {
                        <li
                          class="hover:bg-mantis-75 even:bg-gainsboro-50 border-y border-light-silver text-black font-medium py-0.5"
                          [class.border-b-0]="!last">
                          <div class="flex w-full justify-between items-center group/link px-2">
                            <span
                              class="cursor-pointer group-hover/link:w-[calc(100%-60px)] group-hover/link:truncate group-hover/link:underline"
                              (click)="goto($event, res.key, val._id)">
                              {{ val.displayName }}
                            </span>
                            <span class="px-2 absolute right-2 cursor-pointer hidden group/tab group-hover/link:inline-block hover:text-mantis-500">
                              <span class="flex items-center" (click)="goto(null, res.key, val._id, true)">
                                new tab
                                <span class="group-hover/tab:translate-x-0.5" nz-icon nzType="right" nzTheme="outline"></span>
                              </span>
                            </span>
                          </div>
                        </li>
                      }
                    </ul>
                  }
                }
              } @else {
                <div class="h-full flex items-center justify-center m-3">
                  <nz-empty />
                </div>
              }
            </div>
          }
        </div>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
</form>
