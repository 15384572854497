import { Injectable } from "@angular/core";
import { UrlTree, Router } from "@angular/router";
import { AppStore } from "app/_shared/stores/app.store";
import { Observable } from "rxjs";
import { NzMessageService } from "ng-zorro-antd/message";

@Injectable({
  providedIn: "root",
})
export class NotLoggedInGuard {
  constructor(private router: Router, private appStore: AppStore, private message: NzMessageService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.appStore.user()) {
      this.message.warning("You are already logged in.");
      return this.router.navigate([""]);
    }
    return true;
  }
}
