import { Routes } from "@angular/router";

export const FullLayout_ROUTES: Routes = [
  {
    path: "",
    loadChildren: () => import("app/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "",
    loadChildren: () => import("app/reports/reports.module").then((m) => m.ReportsModule),
  },
  {
    path: "",
    loadChildren: () => import("app/users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "",
    loadChildren: () => import("app/settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "",
    loadChildren: () => import("app/companies/companies.module").then((m) => m.CompaniesModule),
  },
  {
    path: "",
    loadChildren: () => import("app/documents/documents.module").then((m) => m.DocumentsModule),
  },
  {
    path: "",
    loadChildren: () => import("app/products/products.module").then((m) => m.ProductsModule),
  },
  {
    path: "",
    loadChildren: () => import("app/batches/batches.module").then((m) => m.BatchesModule),
  },
  {
    path: "",
    loadChildren: () => import("app/warehouses/warehouses.module").then((m) => m.WarehousesModule),
  },
  {
    path: "",
    loadChildren: () => import("app/orders/orders.module").then((m) => m.OrdersModule),
  },
  {
    path: "",
    loadChildren: () => import("app/templates/templates.module").then((m) => m.TemplatesModule),
  },
];
