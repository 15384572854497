import { IUser } from "app/_shared/types/models/user";

export enum ESharepointReport {
  BUSINESS_LOGISTIC = "report_business_logistic",
  BUSINESS_LOGISTIC_IN = "report_business_logistic_IN_orders",
  FINANCIAL_PAYMENT_MONITORING = "financial_payment_monitoring_report",
  BATCH = "batch_report",
  ORDER_KEY_MILESTONE = "order_key_milestone_report",
  PRODUCT = "product_database",
  COMPANY = "company_database",
  COUNTRY = "country_database",
  MARKET = "market_database",
  ADDRESS = "address_database",
  WAREHOUSE_STOCKS = "stock_by_warehouse",
}

export interface ISharepointReport {
  name: ESharepointReport;
  isSynced: boolean;
}

export interface ISharepointSync {
  _id: string;

  isActive: boolean;
  isProcessing: boolean;
  verificationCount: number;
  reports: ISharepointReport[];
  user: IUser;

  createdAt: string;
  updatedAt: string;
}
