<app-log-form (onChange)="onFilterChanged($event)" />
@if (loading) {
  @for (el of empty; track $index) {
    <div class="h-[74px] my-2 mx-1 bg-gainsboro-50 shadow animate-pulse"></div>
  }
} @else {
  @for (log of logs; track log._id) {
    @if (!log.properties.length) {
      <div class="my-2 mx-1 bg-lotion">
        <app-log-drawer-entity [log]="log" class="w-full"></app-log-drawer-entity>
      </div>
    } @else {
      <nz-collapse nzExpandIconPosition="right" class="my-2 mx-1 bg-lotion">
        <nz-collapse-panel [nzActive]="false" [nzHeader]="headerTemplate">
          <app-log-drawer-properties [log]="log"></app-log-drawer-properties>
        </nz-collapse-panel>
        <ng-template #headerTemplate>
          <app-log-drawer-entity [log]="log" class="w-full" [isCollapse]="true"></app-log-drawer-entity>
        </ng-template>
      </nz-collapse>
    }
  }
}
@if (logs.length >= 100) {
  <button nz-button nzBlock nzType="default" [nzLoading]="loadingMore" (click)="load()">Load more</button>
}
