import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { debounceTime, tap, finalize, distinctUntilChanged } from "rxjs";
import { SearchService } from "app/_shared/services/search.service";
import { SearchResult } from "app/_shared/types/dashboard";
import { isEqual } from "lodash";
import { Router } from "@angular/router";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styles: ``,
})
export class SearchComponent {
  searchForm = this.fb.group({
    query: this.fb.control<string>(null, []),
  });

  visible: boolean;
  loading: boolean;
  query: string;

  result: SearchResult[] = [];
  hasResults: boolean = true;

  constructor(private router: Router, private fb: FormBuilder, private searchService: SearchService) {
    this.onChange();
  }

  onChange() {
    this.searchForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(isEqual),
        tap(({ query }) => (this.loading = !!query))
      )
      .subscribe(({ query }) => {
        this.query = query;
        if (query?.length > 2) {
          this.searchService
            .search(this.query)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((data) => {
              this.visible = true;
              this.result = data;
              this.hasResults = data.some((dt) => !!dt.value.length);
            });
        } else {
          this.loading = false;
          this.result = [];
        }
      });
  }

  onClickInside() {
    this.visible = true;
  }

  onClickOutside(event: Event) {
    if ((event as any).srcElement?.id === "searchInput") return;
    this.visible = false;
  }

  goto(event: MouseEvent, key: string, id: string, newTab: boolean = false) {
    const newUrl = `${key}/${id}`;
    if (newTab || event?.ctrlKey || event?.metaKey) {
      window.open(newUrl, "_blank");
      return true;
    }
    this.searchForm.reset();
    this.visible = false;
    const url = this.router.url;
    if (url.includes(key)) {
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate([newUrl]);
      });
      return true;
    }
    return this.router.navigate([newUrl]);
  }
}
