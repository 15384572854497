import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ICurrency } from "app/_shared/types/models/setting";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class CurrencyService {
  apiUrl: string = environment.api + "api/currencies";

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<ICurrency[]>(this.apiUrl);
  }

  create(currency: { code: string; description: string }) {
    return this.http.post<ICurrency>(this.apiUrl, currency);
  }

  update(currencyId: string, currency: { code: string; description: string }) {
    return this.http.put<ICurrency>(`${this.apiUrl}/${currencyId}`, currency);
  }

  delete(currencyId: string) {
    return this.http.delete<ICurrency>(`${this.apiUrl}/${currencyId}`);
  }
}
