import { EOrderType } from "app/_shared/types/models/order";

export enum Permission {
  //logs
  VIEW_LOGS = "VIEW_LOGS",
  //sharepoint
  SYNC_WITH_SHAREPOINT = "SYNC_WITH_SHAREPOINT",
  //dashboards
  VIEW_OPERATION_DASHBOARD = "VIEW_OPERATION_DASHBOARD",
  VIEW_FINANCE_DASHBOARD = "VIEW_FINANCE_DASHBOARD",
  //reports
  VIEW_BUSINESS_SALES_REPORT = "VIEW_BUSINESS_SALES_REPORT",
  VIEW_BUSINESS_SALES_RESULTS_REPORT = "VIEW_BUSINESS_SALES_RESULTS_REPORT",
  VIEW_BUSINESS_PURCHASE_REPORT = "VIEW_BUSINESS_PURCHASE_REPORT",
  VIEW_BUSINESS_LOGISTIC_REPORT = "VIEW_BUSINESS_LOGISTIC_REPORT",
  VIEW_BUSINESS_INVENTORY_REPORT = "VIEW_BUSINESS_INVENTORY_REPORT",
  VIEW_FINANCIAL_GROSS_MARGIN_ORDER_REPORT = "VIEW_FINANCIAL_GROSS_MARGIN_ORDER_REPORT",
  VIEW_FINANCIAL_GROSS_MARGIN_PRODUCT_REPORT = "VIEW_FINANCIAL_GROSS_MARGIN_PRODUCT_REPORT",
  VIEW_FINANCIAL_PAYMENT_MONITORING_REPORT = "VIEW_FINANCIAL_PAYMENT_MONITORING_REPORT",
  VIEW_LOGISTIC_OPEN_MILESTONE_REPORT = "VIEW_LOGISTIC_OPEN_MILESTONE_REPORT",
  VIEW_LOGISTIC_ORDER_KEY_MILESTONE_OVERVIEW_REPORT = "VIEW_LOGISTIC_ORDER_KEY_MILESTONE_OVERVIEW_REPORT",
  VIEW_LOGISTIC_PRODUCT_KEY_MILESTONE_OVERVIEW_REPORT = "VIEW_LOGISTIC_PRODUCT_KEY_MILESTONE_OVERVIEW_REPORT",
  //users
  VIEW_ROLES = "VIEW_ROLES",
  MANAGE_ROLES = "MANAGE_ROLES",
  VIEW_USERS = "VIEW_USERS",
  MANAGE_USERS = "MANAGE_USERS",
  //settings
  VIEW_ADDRESSES = "VIEW_ADDRESSES",
  MANAGE_ADDRESSES = "MANAGE_ADDRESSES",
  VIEW_COMPANY_TYPES = "VIEW_COMPANY_TYPES",
  MANAGE_COMPANY_TYPES = "MANAGE_COMPANY_TYPES",
  VIEW_REGIONS = "VIEW_REGIONS",
  MANAGE_REGIONS = "MANAGE_REGIONS",
  VIEW_DOCUMENT_CATEGORIES = "VIEW_DOCUMENT_CATEGORIES",
  MANAGE_DOCUMENT_CATEGORIES = "MANAGE_DOCUMENT_CATEGORIES",
  VIEW_CURRENCIES = "VIEW_CURRENCIES",
  MANAGE_CURRENCIES = "MANAGE_CURRENCIES",
  VIEW_EMAIL_CC = "VIEW_EMAIL_CC",
  MANAGE_EMAIL_CC = "MANAGE_EMAIL_CC",
  VIEW_FILE_TEMPLATES = "VIEW_FILE_TEMPLATES",
  MANAGE_FILE_TEMPLATES = "MANAGE_FILE_TEMPLATES",
  VIEW_REPORTING_MARKETS = "VIEW_REPORTING_MARKETS",
  MANAGE_REPORTING_MARKETS = "MANAGE_REPORTING_MARKETS",
  //documents
  VIEW_DOCUMENTS = "VIEW_DOCUMENTS",
  MANAGE_DOCUMENTS = "MANAGE_DOCUMENTS",
  //companies
  VIEW_COMPANIES = "VIEW_COMPANIES",
  MANAGE_COMPANIES = "MANAGE_COMPANIES",
  VIEW_CONTACTS = "VIEW_CONTACTS",
  MANAGE_CONTACTS = "MANAGE_CONTACTS",
  //products
  VIEW_PRODUCTS = "VIEW_PRODUCTS",
  MANAGE_PRODUCTS = "MANAGE_PRODUCTS",
  VIEW_PRODUCT_FAMILIES = "VIEW_PRODUCT_FAMILIES",
  MANAGE_PRODUCT_FAMILIES = "MANAGE_PRODUCT_FAMILIES",
  VIEW_PRODUCT_PRICES = "VIEW_PRODUCT_PRICES",
  VIEW_PRODUCT_COGS = "VIEW_PRODUCT_COGS",
  MANAGE_PRODUCT_COGS = "MANAGE_PRODUCT_COGS",
  //batches
  VIEW_BATCHES = "VIEW_BATCHES",
  MANAGE_BATCHES = "MANAGE_BATCHES",
  //warehouses
  VIEW_WAREHOUSES = "VIEW_WAREHOUSES",
  MANAGE_WAREHOUSES = "MANAGE_WAREHOUSES",
  VIEW_INVENTORY_ITEMS = "VIEW_INVENTORY_ITEMS",
  MANAGE_INVENTORY_ITEMS = "MANAGE_INVENTORY_ITEMS",
  //templates
  VIEW_PAYMENT_TEMPLATES = "VIEW_PAYMENT_TEMPLATES",
  MANAGE_PAYMENT_TEMPLATES = "MANAGE_PAYMENT_TEMPLATES",
  VIEW_MILESTONE_TEMPLATES = "VIEW_MILESTONE_TEMPLATES",
  MANAGE_MILESTONE_TEMPLATES = "MANAGE_MILESTONE_TEMPLATES",
  VIEW_IN_TRANSIT_TIME_TEMPLATES = "VIEW_IN_TRANSIT_TIME_TEMPLATES",
  MANAGE_IN_TRANSIT_TIME_TEMPLATES = "MANAGE_IN_TRANSIT_TIME_TEMPLATES",
  //orders
  VIEW_ORDERS = "VIEW_ORDERS",
  MANAGE_ORDERS = "MANAGE_ORDERS",
}

interface RawPermission {
  name: string;
  permissions: Permission[];
}

export const RawPermissions: RawPermission[] = [
  {
    name: "Utilities",
    permissions: Object.values([Permission.VIEW_LOGS, Permission.SYNC_WITH_SHAREPOINT]),
  },
  {
    name: "Dashboards",
    permissions: Object.values([Permission.VIEW_OPERATION_DASHBOARD, Permission.VIEW_FINANCE_DASHBOARD]),
  },
  {
    name: "Reports",
    permissions: Object.values([
      Permission.VIEW_BUSINESS_SALES_REPORT,
      Permission.VIEW_BUSINESS_SALES_RESULTS_REPORT,
      Permission.VIEW_BUSINESS_PURCHASE_REPORT,
      Permission.VIEW_BUSINESS_LOGISTIC_REPORT,
      Permission.VIEW_BUSINESS_INVENTORY_REPORT,
      Permission.VIEW_FINANCIAL_GROSS_MARGIN_ORDER_REPORT,
      Permission.VIEW_FINANCIAL_GROSS_MARGIN_PRODUCT_REPORT,
      Permission.VIEW_FINANCIAL_PAYMENT_MONITORING_REPORT,
      Permission.VIEW_LOGISTIC_OPEN_MILESTONE_REPORT,
      Permission.VIEW_LOGISTIC_ORDER_KEY_MILESTONE_OVERVIEW_REPORT,
      Permission.VIEW_LOGISTIC_PRODUCT_KEY_MILESTONE_OVERVIEW_REPORT,
    ]),
  },
  {
    name: "Users",
    permissions: Object.values([
      Permission.VIEW_ROLES,
      Permission.MANAGE_ROLES,
      Permission.VIEW_USERS,
      Permission.MANAGE_USERS,
    ]),
  },
  {
    name: "Settings",
    permissions: Object.values([
      Permission.VIEW_ADDRESSES,
      Permission.MANAGE_ADDRESSES,
      Permission.VIEW_COMPANY_TYPES,
      Permission.MANAGE_COMPANY_TYPES,
      Permission.VIEW_REPORTING_MARKETS,
      Permission.MANAGE_REPORTING_MARKETS,
      Permission.VIEW_REGIONS,
      Permission.MANAGE_REGIONS,
      Permission.VIEW_DOCUMENT_CATEGORIES,
      Permission.MANAGE_DOCUMENT_CATEGORIES,
      Permission.VIEW_CURRENCIES,
      Permission.MANAGE_CURRENCIES,
      Permission.VIEW_EMAIL_CC,
      Permission.MANAGE_EMAIL_CC,
      Permission.VIEW_FILE_TEMPLATES,
      Permission.MANAGE_FILE_TEMPLATES,
    ]),
  },
  {
    name: "Documents",
    permissions: Object.values([Permission.VIEW_DOCUMENTS, Permission.MANAGE_DOCUMENTS]),
  },
  {
    name: "Companies",
    permissions: Object.values([
      Permission.VIEW_COMPANIES,
      Permission.MANAGE_COMPANIES,
      Permission.VIEW_CONTACTS,
      Permission.MANAGE_CONTACTS,
    ]),
  },
  {
    name: "Products",
    permissions: Object.values([
      Permission.VIEW_PRODUCTS,
      Permission.MANAGE_PRODUCTS,
      Permission.VIEW_PRODUCT_FAMILIES,
      Permission.MANAGE_PRODUCT_FAMILIES,
      Permission.VIEW_PRODUCT_PRICES,
      Permission.VIEW_PRODUCT_COGS,
      Permission.MANAGE_PRODUCT_COGS,
    ]),
  },
  {
    name: "Batches",
    permissions: Object.values([Permission.VIEW_BATCHES, Permission.MANAGE_BATCHES]),
  },
  {
    name: "Warehouses",
    permissions: Object.values([
      Permission.VIEW_WAREHOUSES,
      Permission.MANAGE_WAREHOUSES,
      Permission.VIEW_INVENTORY_ITEMS,
      Permission.MANAGE_INVENTORY_ITEMS,
    ]),
  },
  {
    name: "Templates",
    permissions: Object.values([
      Permission.VIEW_PAYMENT_TEMPLATES,
      Permission.MANAGE_PAYMENT_TEMPLATES,
      Permission.VIEW_MILESTONE_TEMPLATES,
      Permission.MANAGE_MILESTONE_TEMPLATES,
      Permission.VIEW_IN_TRANSIT_TIME_TEMPLATES,
      Permission.MANAGE_IN_TRANSIT_TIME_TEMPLATES,
    ]),
  },
  {
    name: "Orders",
    permissions: Object.values([Permission.VIEW_ORDERS, Permission.MANAGE_ORDERS]),
  },
];

export interface IRole {
  _id?: string;
  displayName: string;
  name: string;
  description: string;
  permissions: Permission[];
  countries: string[];
  orderTypes: EOrderType[];
  isDeleted: boolean;
  createdAt?: string;
  updatedAt?: string;
}
