import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ITable, TableState, filterTableState } from "app/_shared/types/models/table";
import { environment } from "environments/environment";
import { TableStore } from "app/_shared/stores/entities/table.store";
import { filter, debounceTime, switchMap } from "rxjs";
import { AppStore } from "app/_shared/stores/app.store";
import { untilDestroyed, UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class TableService {
  apiUrl: string = environment.api + "api/tables";

  constructor(
    private http: HttpClient,
    private appStore: AppStore,
    private tableStore: TableStore,
  ) {}

  observe() {
    this.tableStore.tables$
      .pipe(
        untilDestroyed(this),
        filter(() => !this.appStore.hasError()),
        filter(() => !!this.appStore.user()),
        debounceTime(1000),
        switchMap((tables) => this.create(tables.filter(filterTableState))),
      )
      .subscribe();
  }

  getOne() {
    return this.http.get<ITable>(this.apiUrl);
  }

  create(tables: TableState[]) {
    return this.http.post<ITable>(this.apiUrl, { tables });
  }
}
