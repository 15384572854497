import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-text-area-edit",
  template: ` <div class="w-full flex flex-col justify-center min-h-[40px]">
    <div class="text-philippine-gray text-sm min-w-[120px]">{{ title | capitalize }}</div>
    <div clickOutside (clickedOutside)="onChange()">
      @if (isEditing) {
        <textarea
          nz-input
          [(ngModel)]="_content"
          nzAutosize
          autofocus
          enterPressed
          (onEnterPressed)="onChange()"></textarea>
      } @else {
        <div class="text-dark break-words whitespace-normal" [ngClass]="['text-' + size]">
          <div class="flex items-center space-x-1">
            <div [innerText]="content ?? ''"></div>
            <div class="text-mantis-500 cursor-pointer" nz-tooltip nzTooltipTitle="Edit" (click)="isEditing = true">
              <span nz-icon nzType="edit" nzTheme="outline"></span>
            </div>
          </div>
        </div>
      }
    </div>
  </div>`,
})
export class TextAreaEditComponent implements OnInit {
  @Input() title: string;
  @Input() size: "sm" | "base" | "lg" | "xl" = "lg";
  @Input() content: string;

  @Output() onContentChange = new EventEmitter<string>();

  isEditing = false;
  _content: string;

  ngOnInit(): void {
    this._content = this.content;
  }

  onChange() {
    this.isEditing = false;
    if (this._content !== this.content) {
      this.onContentChange.emit(this._content);
    }
  }
}
