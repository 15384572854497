import { Injectable } from "@angular/core";
import { createStore } from "@ngneat/elf";
import { selectAllEntities, setEntities, upsertEntities, withEntities, addEntities } from "@ngneat/elf-entities";
import { map, Observable } from "rxjs";
import { IComment } from "app/_shared/types/models/comment";
import { orderBy } from "lodash";
import { formatDistance, parseISO } from "date-fns";

const store = createStore({ name: "comments" }, withEntities<IComment, "_id">({ idKey: "_id" }));

@Injectable({ providedIn: "root" })
export class CommentStore {
  getAll$: Observable<IComment[]> = store.pipe(
    selectAllEntities(),
    map((entities) =>
      entities
        .filter((entity) => !entity.isDeleted)
        .map((entity) => ({
          ...entity,
          time: entity.createdAt ? formatDistance(parseISO(entity.createdAt), new Date()) : null,
        }))
    ),
    map((entities) => orderBy(entities, ["createdAt"], ["desc"]))
  );

  setEntities(state: IComment[]) {
    store.update(setEntities(state));
  }

  addEntities(state: IComment[]) {
    store.update(addEntities(state));
  }

  updateEntities(state: IComment[]) {
    store.update(upsertEntities(state));
  }

  destroy() {
    store.reset();
  }
}
