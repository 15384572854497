import { Injectable } from "@angular/core";
import { createStore } from "@ngneat/elf";
import {
  addEntities,
  deleteEntities,
  getAllEntities,
  getEntity,
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withEntities,
} from "@ngneat/elf-entities";
import { IProduct } from "app/_shared/types/models/product";
import { map, take } from "rxjs";

const store = createStore({ name: "products" }, withEntities<IProduct, "_id">({ idKey: "_id" }));

@Injectable({ providedIn: "root" })
export class ProductStore {
  findAll$ = store.pipe(selectAllEntities(), take(1));
  getAll$ = store.pipe(
    selectAllEntities(),
    map((entities) => entities.filter((entity) => !entity.isDiscontinued))
  );
  getOne$ = (id: string) => store.pipe(selectEntity(id));

  getAll = () =>
    store.pipe(
      selectAllEntities(),
      map((entities) => entities.filter((entity) => !entity.isDiscontinued))
    );

  data = () => store.query(getAllEntities()).filter((entity) => !entity.isDiscontinued);
  getById = (id: string) => store.query(getEntity(id));

  setEntities(state: IProduct[]) {
    store.update(setEntities(state));
  }

  addEntities(state: IProduct[]) {
    store.update(addEntities(state, { prepend: true }));
  }

  updateEntities(state: IProduct[]) {
    store.update(upsertEntities(state));
  }

  removeEntities(ids: string[]) {
    store.update(deleteEntities(ids));
  }

  destroy() {
    store.reset();
  }
}
