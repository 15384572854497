import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AppStore } from "app/_shared/stores/app.store";
import { LogDrawerComponent } from "app/_shared/components/log";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { Permission } from "app/_shared/types/models/role";
import {
  ProfileEditComponent,
  ProfileEditComponentData,
} from "app/_shared/components/profile-edit/profile-edit.component";
import { IUser, AppUser } from "app/_shared/types/models/user";
import * as Sentry from "@sentry/angular";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: "app-header",
  template: `
    <div class="h-12 bg-white shadow flex items-center px-4 justify-between overflow-x-auto">
      <div class="flex items-center gap-x-2">
        <div>
          <span class="text-base cursor-pointer transition-all" (click)="toggleCollapse()">
            <i class="hover:text-mantis-500" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
          </span>
        </div>
        <app-search />
      </div>
      <div class="flex flex-row-reverse items-center gap-x-2 min-w-[300px]">
        @if (hasViewLogPermission) {
          <img
            class="cursor-pointer h-6 w-6"
            [ngSrc]="'assets/logo/log.png'"
            alt="log"
            (click)="showLogs()"
            width="20"
            height="20" />
        }
        @if (hasSharepointSyncPermission) {
          <img
            class="cursor-pointer"
            [ngSrc]="'assets/logo/sharepoint.png'"
            width="24"
            height="24"
            alt="sharepoint"
            nz-popover
            nzPopoverTrigger="click"
            nzPopoverPlacement="bottomRight"
            [nzPopoverContent]="contentTemplate" />
          <ng-template #contentTemplate>
            <app-sharepoint-sync />
          </ng-template>
        }
        <div
          class="flex cursor-pointer"
          nz-dropdown
          nzTrigger="click"
          [nzDropdownMenu]="avatar"
          nzOverlayClassName="-mt-2">
          <span class="px-1">{{ user?.displayName }}</span>
          <app-user-avatar [user]="user" />
        </div>
      </div>
    </div>
    <nz-dropdown-menu #avatar="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item (click)="profile()" class="min-w-[100px]">
          <span class="w-full flex items-center">
            <span nz-icon nzType="user" nzTheme="outline"></span>
            <span class="pl-2">Profile</span>
          </span>
        </li>
        <li nz-menu-item nzDanger (click)="logout()" class="min-w-[100px]">
          <span class="w-full flex items-center">
            <span nz-icon nzType="logout" nzTheme="outline"></span>
            <span class="pl-2">Sign out</span>
          </span>
        </li>
      </ul>
    </nz-dropdown-menu>
  `,
})
export class HeaderComponent {
  user: AppUser;
  isCollapsed: boolean;

  hasViewLogPermission = false;
  hasSharepointSyncPermission = false;

  constructor(
    private router: Router,
    private modalService: NzModalService,
    private drawerService: NzDrawerService,
    private appStore: AppStore,
  ) {
    this.appStore.isCollapsed$.subscribe((isCollapsed) => {
      this.isCollapsed = isCollapsed;
    });
    this.appStore.permissions$.subscribe((permissions) => {
      this.hasViewLogPermission = permissions.includes(Permission.VIEW_LOGS);
      this.hasSharepointSyncPermission = permissions.includes(Permission.SYNC_WITH_SHAREPOINT);
    });
    this.appStore.user$.subscribe((user) => {
      this.user = user;
      Sentry.setUser(!user ? null : { id: (user as any)._id, email: user.email, username: user.displayName });
    });
  }

  toggleCollapse() {
    this.appStore.setState({ isCollapsed: !this.isCollapsed });
  }

  async logout() {
    this.appStore.setState({ user: null, permissions: [] });
    await this.router.navigate(["login"]);
  }

  profile() {
    const modal = this.modalService.create<ProfileEditComponent, ProfileEditComponentData>({
      nzTitle: "Profile",
      nzContent: ProfileEditComponent,
      nzData: {
        user: this.user as unknown as IUser,
      },
      nzMaskClosable: false,
      nzWidth: 350,
    });

    // close event
    modal.afterClose.subscribe((data: AppUser) => {
      if (data) {
        this.user = { ...this.user, ...data };
        this.appStore.setState({ user: this.user });
      }
    });
  }

  sync(): void {}

  showLogs(): void {
    this.drawerService.create({
      nzTitle: "Activity logs",
      nzContent: LogDrawerComponent,
      nzWidth: 700,
    });
  }
}
