import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { AppService } from "app/_shared/services/app.service";

@Injectable({
  providedIn: "root",
})
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private appService: AppService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf("s3") > -1) {
      return next.handle(request);
    }

    return this.appService.acquireToken().pipe(
      switchMap((accessToken) => {
        // Clone the request and set the new header
        const clonedRequest = request.clone({
          setHeaders: {
            ...(request.method === "POST" &&
            (request.url.indexOf("documents") > -1 || request.url.indexOf("text-analyze") > -1)
              ? { Accept: "multipart/form-data" }
              : { "Content-Type": "application/json" }),
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
          },
        });
        // Pass the cloned request instead of the original request to the next handle
        return next.handle(clonedRequest);
      }),
    );
  }
}
