import { Component, Input } from "@angular/core";

@Component({
  selector: "app-description",
  template: `
    <div class="w-full flex flex-col justify-center min-h-[20px]">
      <div class="text-philippine-gray text-sm min-w-[120px]">{{ title | capitalize }}</div>
      <div class="text-dark break-words whitespace-normal">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class DescriptionComponent {
  @Input() title: string;
}
