import { ICellEditorAngularComp } from "ag-grid-angular";
import { Component, ViewChild } from "@angular/core";
import { ICellEditorParams } from "ag-grid-community";
import { parseISO, isBefore, startOfDay } from "date-fns";
import { NzDatePickerComponent } from "ng-zorro-antd/date-picker";

interface Option {
  disablePastDate: boolean;
}

@Component({
  selector: "ag-grid-custom-edit-date",
  template: ` <nz-date-picker
    #datePicker
    class="w-full"
    nzFormat="dd/MM/yyyy"
    nzSize="small"
    nzBorderless
    [nzDisabledDate]="disablePastDate ? isPastDate : null"
    [nzShowToday]="false"
    [nzAllowClear]="false"
    [(ngModel)]="date" />`,
})
export class AgGridCustomEditDateComponent implements ICellEditorAngularComp {
  params: ICellEditorParams;

  date: Date;
  disablePastDate: boolean = true;

  @ViewChild("datePicker") datePicker!: NzDatePickerComponent;

  isPastDate = (current: Date): boolean => isBefore(current, startOfDay(new Date()));

  agInit(params: ICellEditorParams & Option): void {
    this.params = params;
    this.disablePastDate = params.disablePastDate != null ? params.disablePastDate : true;
    this.date = params.value ? parseISO(params.value) : null;
  }

  getValue(): string | null {
    return this.date ? this.date.toISOString() : null;
  }
}
