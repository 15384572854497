import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "app/_shared/guards/auth.guard";
import { NotLoggedInGuard } from "app/_shared/guards/not-logged-in.guard";

import { BlankLayout_ROUTES } from "app/_shared/routes/blank-layout.routes";
import { FullLayout_ROUTES } from "app/_shared/routes/full-layout.routes";

import { BlankComponent } from "app/layouts/blank/blank.component";
import { FullComponent } from "app/layouts/full/full.component";

const routes: Routes = [
  {
    path: "",
    component: FullComponent,
    canActivate: [AuthGuard],
    children: FullLayout_ROUTES,
  },
  {
    path: "",
    component: BlankComponent,
    canActivate: [NotLoggedInGuard],
    children: BlankLayout_ROUTES,
  },
  {
    path: "**",
    redirectTo: "/",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: "always" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
