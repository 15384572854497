import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { Component } from "@angular/core";

@Component({
  selector: "ag-grid-custom-cell-link",
  template: `
    <div class="flex flex-col">
      @for (text of texts; track $index) {
        <span>{{ text }}</span>
      }
    </div>
  `,
})
export class AgGridCustomCellTextListComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  texts: string[] = [];

  constructor() {}

  ngOnInit(): void {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams & { texts: string[] }): void {
    this.params = params;
    this.texts = params.texts;
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    return true;
  }
}
