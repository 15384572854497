import { IMilestone } from "app/_shared/types/models/milestone";
import { EOrderContract, IOrder, IOrderContract } from "app/_shared/types/models/order";
import { IMilestoneTemplate } from "app/_shared/types/models/template";
import { IUser } from "app/_shared/types/models/user";
import { IProductCogs } from "app/_shared/types/models/product";

export const models = [
  "Role",
  "User",
  "Address",
  "City",
  "CompanyType",
  "ReportingMarket",
  "Region",
  "DocumentCategory",
  "Currency",
  "EmailCC",
  "Document",
  "Company",
  "Contact",
  "Product",
  "ProductFamily",
  "ProductPacking",
  "ProductFormulation",
  "ProductPrice",
  "ProductCogs",
  "Batch",
  "InventoryItem",
  "PaymentTemplate",
  "InTransitTimeTemplate",
  "MilestoneTemplate",
  "Order",
  "OrderItem",
  "OrderItemBatch",
  "OrderContractPurchase",
  "OrderContractTransformation",
  "OrderContractRepackagingRelabeling",
  "OrderContractLoss",
  "OrderContractFreight",
  "OrderContractSale",
  "OrderContractCommission",
  "OrderContractPostDelivery",
  "Milestone",
  "DocumentSign",
  "Email",
  "FileTemplate",
] as const;
export type Model = (typeof models)[number];

export const actions = ["created", "updated", "removed", "uploaded"] as const;
export type Action = (typeof actions)[number];

export const logPropertyTypes = [
  "undefined",
  "null",
  "number",
  "string",
  "boolean",
  "date",
  "object",
  "array",
] as const;
export type LogPropertyType = (typeof logPropertyTypes)[number];

export interface ILog {
  _id: string;
  action: Action;
  title: string;
  user: IUser;
  model: Model;
  entity: any;
  properties?: ILogProperty[];
  isDeleted: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface ILogProperty {
  name: string;
  newValue: any;
  oldValue: any;
}

export const logModelMap = {
  City: "Port",
};

const orderFieldMap: { [K in keyof Partial<IOrder>]: string } = {
  validityDate: "Validated At",
  lastUpdatedAt: "Updated At",
  source: "Supplier",
  destination: "Client",
};

const orderContractFieldMap: { [K in keyof Partial<IOrderContract>]: string } = {
  paymentCurrency: "Currency",
  paymentExchangeRate: "Exchange Rate",
};

const milestoneFieldMap: { [K in keyof Partial<IMilestone>]: string } = {
  expectedDate: "Expected at",
  doneDate: "Done at",
  trackSheetConfirmed: "Track Sheet",
  labelConfirmed: "Label",
  documentsVerified: "Documents",
};

const milestoneTemplateFieldMap: { [K in keyof Partial<IMilestoneTemplate>]: string } = {
  VALIDATION: "Validation",
  [EOrderContract.PURCHASE]: "Purchase",
  [EOrderContract.TRANSFORMATION]: "Transformation",
  [EOrderContract.REPACKAGING_RELABELING]: "Repackaging/Relabeling",
  [EOrderContract.LOSS]: "Loss",
  [EOrderContract.FREIGHT_ONE]: "Freight",
  [EOrderContract.FREIGHT_TWO]: "Freight",
  [EOrderContract.FREIGHT_THREE]: "Freight",
  [EOrderContract.COMMISSION]: "Commission",
  [EOrderContract.SALE]: "Sale",
  [EOrderContract.POST_DELIVERY]: "Post Delivery",
};

const productCogsFieldMap: { [K in keyof Partial<IProductCogs>]: string } = {
  shippingCost: "Avg shipping cost",
};

export const logFieldNameMap = {
  ...orderFieldMap,
  ...orderContractFieldMap,
  ...milestoneFieldMap,
  ...milestoneTemplateFieldMap,
  ...productCogsFieldMap,
};

export interface LogFilter {
  query: string;
  model: Model;
  user: string;
}
