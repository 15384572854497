<nz-spin [nzSpinning]="spinning" class="w-[300px]">
  <div class="size-full flex flex-col gap-1">
    <div class="text-black text-xl font-semibold">Sharepoint sync</div>
    <div class="flex items-center justify-between">
      <span class="flex items-center gap-x-0.5">
        <app-user-avatar [user]="sharepointSync?.user" [size]="16" />
        <span>{{ sharepointSync?.user.displayName | startCase }}</span>
      </span>
      <span>{{ sharepointSync?.createdAt | date: "dd/MM/yyyy HH:mm" }}</span>
    </div>
    @for (report of reports; track report.name) {
      <div class="flex items-center justify-between">
        <span>{{ report.name | underscore }}</span>
        <span><app-bool [value]="report.isSynced" /></span>
      </div>
    }

    <button nz-button nzBlock nzSize="large" nzType="primary" [nzLoading]="sharepointSync?.isProcessing" (click)="sync()">Sync</button>
  </div>
</nz-spin>
