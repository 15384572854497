import { Pipe, PipeTransform } from "@angular/core";
import { IAddress } from "app/_shared/types/models/address";
import { formatAddress } from "app/_shared/utils/app.utils";

@Pipe({
  name: "address",
})
export class AddressPipe implements PipeTransform {
  transform(value: IAddress, sameLine: boolean = true): string {
    return formatAddress(value, sameLine);
  }
}
