@if (hasError) {
  <nz-layout class="h-screen">
    <div class="size-full flex items-center justify-center">
      @if (status === 401) {
        <nz-result nzStatus="403" nzTitle="Unauthorized" nzSubTitle="Sorry, you are not authorized to access this app.">
          <div nz-result-extra>
            <button nz-button nzType="primary" (click)="reload()">Reload</button>
            <button nz-button nzType="primary" (click)="logout()">Logout</button>
          </div>
        </nz-result>
      } @else {
        <nz-result nzStatus="500" nzTitle="500" nzSubTitle="Sorry, there is an error on server.">
          <div nz-result-extra>
            <button nz-button nzType="primary" (click)="reload()">Reload</button>
            <button nz-button nzType="primary" (click)="logout()">Logout</button>
          </div>
        </nz-result>
      }
    </div>
  </nz-layout>
} @else {
  @if (loading) {
    <nz-layout class="h-screen">
      <div class="size-full flex items-center justify-center">Loading...</div>
    </nz-layout>
  } @else {
    <nz-layout class="h-screen">
      <nz-sider
        class="min-h-screen shadow overflow-y-auto bg-white"
        nzCollapsible
        [nzWidth]="230"
        [nzCollapsedWidth]="50"
        [nzCollapsed]="isCollapsed"
        [nzTrigger]="null">
        <div class="max-h-12">
          <a href="/">
            <img
              src=""
              alt=""
              class="max-h-12 w-auto mx-auto transition-all"
              nz-image
              nzDisablePreview
              [nzSrc]="isCollapsed ? 'assets/logo/logo-fold.png' : 'assets/logo/logo.png'"
              [nzSrcset]="
                isCollapsed ? 'assets/logo/logo-fold2x.png 2x, assets/logo/logo-fold3x.png 3x' : 'assets/logo/logo2x.png 2x, assets/logo/logo3x.png 3x'
              " />
          </a>
        </div>
        <app-side-bar />
      </nz-sider>
      <nz-layout class="bg-gainsboro-100">
        <nz-header class="p-0 w-full h-12">
          <app-header />
        </nz-header>
        <nz-content class="m-2 overflow-x-auto">
          @if (hasPermission) {
            <div class="min-w-[1024px] h-full">
              @if (isDevelopment) {
                <nz-alert class="w-full mb-2" nzType="info" nzMessage="This environment is set up for testing purposes." nzBanner />
              }
              <router-outlet></router-outlet>
            </div>
          } @else {
            <div class="h-full flex items-center justify-center">
              <nz-result nzStatus="403" nzSubTitle="Sorry, you are not authorized to access this page."></nz-result>
            </div>
          }
        </nz-content>
      </nz-layout>
    </nz-layout>
  }
}
