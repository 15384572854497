import { Pipe, PipeTransform } from "@angular/core";
import { IPhone } from "app/_shared/types/models/user";
import { head } from "lodash";

@Pipe({
  name: "phone",
})
export class PhonePipe implements PipeTransform {
  transform(value: IPhone[]): string {
    return head(value)?.number ?? "";
  }
}
