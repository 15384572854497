import { Directive, ElementRef, Output, EventEmitter, HostListener } from "@angular/core";

@Directive({
  selector: "[enterPressed]",
})
export class EnterPressedDirective {
  constructor(private _elementRef: ElementRef) {}

  @Output() onEnterPressed = new EventEmitter<void>();

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === "Enter" && !event.shiftKey) {
      this.onEnterPressed.emit();
    }
  }
}
