import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { IComment } from "app/_shared/types/models/comment";

@Injectable({
  providedIn: "root",
})
export class CommentService {
  apiUrl: string = environment.api + "api/comments";

  constructor(private http: HttpClient) {}

  getAll(cursor: number) {
    return this.http.get<IComment[]>(`${this.apiUrl}?cursor=${cursor}`);
  }

  create(content: string, parent?: string) {
    return this.http.post<IComment>(this.apiUrl, { content, parent });
  }

  update(commentId: string, content: string) {
    return this.http.put<IComment>(`${this.apiUrl}/${commentId}`, { content });
  }

  delete(commentId: string) {
    return this.http.delete<IComment>(`${this.apiUrl}/${commentId}`);
  }
}
