import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { Component } from "@angular/core";

interface GridAction {
  name: string;
  functionName: string;
  requireConfirm: boolean;
  isDanger: boolean;
  isDisabled: boolean;
}

@Component({
  selector: "ag-grid-custom-cell-action",
  template: `
    <a
      [nzDisabled]="action.isDisabled"
      nz-typography
      nz-popconfirm
      [nzCondition]="!action.requireConfirm"
      [nzOkDanger]="action.isDanger"
      nzPopconfirmTitle="Are you sure ?"
      (nzOnConfirm)="applyAction(action.functionName ?? action.name)">
      <span
        [ngClass]="{
          'hover:underline': !action.isDisabled,
          '!text-gray': action.isDisabled,
          'text-red-500': action.isDanger,
          'text-blue-500': !action.isDanger
        }">
        {{ action.functionName ? action.name : (action.name | titlecase) }}
      </span>
    </a>
  `,
})
export class AgGridCustomCellActionComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  action: GridAction;

  constructor() {}

  ngOnInit(): void {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams & { action: GridAction }): void {
    this.params = params;
    this.action = params.action;
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  async applyAction(name: string): Promise<void> {
    this.params.context.component[name](this.params.data);
  }
}
