export const autoTips: Record<string, Record<string, string>> = {
  default: {
    required: "This field is required.",
    duplicated: "This field is duplicated.",
    invalid: "This field is invalid.",
    email: "This email is invalid.",
    min: "This number is invalid.",
    max: "This number is invalid.",
    confirm: "These two fields are inconsistent.",
    noMatch: "This field does not match.",
    same: "This field has not been changed.",
    before: "This field is before than the minimum date.",
    lower: "This field is lower than the minimum value.",
    minlength: "This field must have 5 characters.",
    maxlength: "This field must have 5 characters.",
  },
};
