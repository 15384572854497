import { Component, Input } from "@angular/core";

@Component({
  selector: "app-display",
  template: `
    <div class="w-full flex flex-col justify-center min-h-[40px]">
      <div class="text-philippine-gray text-sm min-w-[120px]">{{ title | capitalize }}</div>
      <div class="text-dark break-words whitespace-normal" [ngClass]="['text-' + size]">
        <div class="display-wrapper">
          <ng-content></ng-content>
        </div>
        <div class="default">-</div>
      </div>
    </div>
  `,
})
export class DisplayComponent {
  @Input() title: string;
  @Input() size: "sm" | "base" | "lg" | "xl" = "lg";
}
