import { ColDef, IAggFunc, IAggFuncParams, ExcelCell, ExcelStyle, SideBarDef } from "ag-grid-community";
import {
  AgGridCustomCellActionComponent,
  AgGridCustomCellActionsComponent,
  AgGridCustomCellAvatarComponent,
  AgGridCustomCellBoolComponent,
  AgGridCustomCellLinkComponent,
  AgGridCustomCellTextListComponent,
  AgGridCustomEditBoolComponent,
  AgGridCustomCellEmailComponent,
  AgGridCustomEditDateComponent,
} from "app/_shared/ag-grid";
import { formatDate, removeUnderscore } from "app/_shared/utils/app.utils";
import { parseISO } from "date-fns";
import { get, round, sum, isNumber } from "lodash";

export const datatables = [
  "users",
  "roles",
  "addresses",
  "cities",
  "company-types",
  "regions",
  "document-categories",
  "currencies",
  "email-ccs",
  "reporting-markets",
  "documents",
  "companies",
  "contacts",
  "products",
  "product-families",
  "product-prices",
  "product-cogs",
  "product-specifications",
  "batches",
  "own-products",
  "warehouses",
  "inventory-items",
  "transactions",
  "payment-templates",
  "in-transit-time-templates",
  "milestone-templates",
  "orders",
  "op-orders-late-approval",
  "op-late-milestones",
  "op-upcoming-milestones",
  "fi-late-milestones",
  "fi-upcoming-milestones",
  "business-sales-report",
  "business-sales-results-report",
  "business-purchase-report",
  "business-logistic-report",
  "financial-gross-margin-order-report",
  "financial-gross-margin-product-report",
  "financial-payment-monitoring-report",
  "logistic-open-milestone-report",
  "logistic-order-key-milestone-overview-report",
  "logistic-product-key-milestone-overview-report",
] as const;
export type TableName = (typeof datatables)[number] | string;

export const userExternalFilters = ["All users", "Active users"] as const;
export type UserExternalFilter = (typeof userExternalFilters)[number];

export const companyExternalFilters = ["All companies", "Active companies"] as const;
export type CompanyExternalFilter = (typeof companyExternalFilters)[number];

export const productPriceExternalFilters = ["All prices", "Active prices"] as const;
export type ProductPriceExternalFilter = (typeof productPriceExternalFilters)[number];

export const productInnerPriceExternalFilters = ["All prices", "Purchase prices", "Sales prices"] as const;
export type ProductInnerPriceExternalFilter = (typeof productInnerPriceExternalFilters)[number];

export const productExternalFilters = [
  "All products",
  "Active products",
  "Discontinued products",
  "Own products",
] as const;
export type ProductExternalFilter = (typeof productExternalFilters)[number];

export const batchExternalFilters = ["All batches", "Active batches"] as const;
export type BatchExternalFilter = (typeof batchExternalFilters)[number];

export const warehouseExternalFilters = ["All warehouses", "Active warehouses"] as const;
export type WarehouseExternalFilter = (typeof warehouseExternalFilters)[number];

export const inventoryExternalFilters = ["All inventory items", "Available inventory items"] as const;
export type InventoryExternalFilter = (typeof inventoryExternalFilters)[number];

export const orderExternalFilters = ["All orders", "Active orders", "Cancelled orders", "On Hold orders"] as const;
export type OrderExternalFilter = (typeof orderExternalFilters)[number];

export const upcomingMilestoneExternalFilters = [
  "All milestones",
  "This week",
  "Next 10 days",
  "Next 30 days",
] as const;
export type UpcomingMilestoneExternalFilter = (typeof upcomingMilestoneExternalFilters)[number];

export const reportExternalFilters = [
  "No range",
  "Custom range",
  "Current year",
  "Current month",
  "Ytd",
  "Quarter one",
  "Quarter two",
  "Quarter three",
  "Quarter four",
  "Half one",
  "Half two",
  "Last year",
] as const;
export type ReportExternalFilter = (typeof reportExternalFilters)[number];

export const externalFilters = [
  ...userExternalFilters,
  ...companyExternalFilters,
  ...productExternalFilters,
  ...productPriceExternalFilters,
  ...productInnerPriceExternalFilters,
  ...batchExternalFilters,
  ...warehouseExternalFilters,
  ...inventoryExternalFilters,
  ...orderExternalFilters,
  ...upcomingMilestoneExternalFilters,
  ...reportExternalFilters,
];
export type ExternalFilter = (typeof externalFilters)[number];

export const contextMenuItems = ["autoSizeAll", "copy", "copyWithHeaders", "resetColumns"];
export const componentMap: { [p: string]: any } = {
  agGridCustomCellAvatarComponent: AgGridCustomCellAvatarComponent,
  agGridCustomCellBoolComponent: AgGridCustomCellBoolComponent,
  agGridCustomCellActionComponent: AgGridCustomCellActionComponent,
  agGridCustomCellActionsComponent: AgGridCustomCellActionsComponent,
  agGridCustomCellLinkComponent: AgGridCustomCellLinkComponent,
  agGridCustomCellEmailComponent: AgGridCustomCellEmailComponent,
  agGridCustomCellTextListComponent: AgGridCustomCellTextListComponent,
  agGridCustomEditBoolComponent: AgGridCustomEditBoolComponent,
  agGridCustomEditDateComponent: AgGridCustomEditDateComponent,
};

export const aggFuncsMap: { [key: string]: IAggFunc } = {
  sum: (params: IAggFuncParams) => round(sum(params.values), 2),
};

export const columnTypesMap: { [key: string]: ColDef } = {
  date: {
    filter: "agDateColumnFilter",
    filterParams: {
      minValidYear: 2010,
      browserDatePicker: false,
      comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        const cellDate = parseISO(cellValue);
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
    },
    valueFormatter: (params) => formatDate(params.value),
  },
  object: {
    filter: "agTextColumnFilter",
    valueGetter: (params) => {
      const obj = get(params.data, params.colDef.field);
      if (obj?.value != null) {
        return `${removeUnderscore(obj.date)} ${obj.value > 0 ? "+" : "-"} ${Math.abs(obj.value)} DAYS`;
      }
      return "";
    },
  },
  bool: {
    cellRenderer: "agGridCustomCellBoolComponent",
    filter: "agSetColumnFilter",
    filterParams: {
      suppressMiniFilter: true,
      suppressSelectAll: true,
      values: [true, false],
    },
    cellDataType: "text",
  },
};

export const rowClassRulesMap: { [key: string]: (params: any) => boolean } = {
  "font-semibold !bg-carrot-orange-50/50": (params) => !!params.node.rowPinned,
};

export const transactionRowClassRulesMap: { [key: string]: (params: any) => boolean } = {
  ...rowClassRulesMap,
  "!bg-red-50": (params) => params?.data?.type === "OUT",
  "!bg-blue-50": (params) => params?.data?.type === "IN",
};

export const excelStyles: ExcelStyle[] = [
  {
    id: "header",
    interior: { color: "#aaaaaa", pattern: "Solid" },
  },
  {
    id: "body",
    interior: { color: "#dddddd", pattern: "Solid" },
  },
];

export const getCell = (text: string | number, styleId?: string): ExcelCell => ({
  styleId: styleId,
  data: { type: isNumber(text) ? "Number" : "String", value: String(text) },
});

export const sideBar: SideBarDef | string | string[] | boolean | null = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        //suppressColumnFilter: true,
        //suppressColumnSelectAll: true,
        //suppressColumnExpandAll: true,
      },
    },
    "filters",
  ],
};
