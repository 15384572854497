import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IUser, UserInputForm } from "app/_shared/types/models/user";

import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  apiUrl: string = environment.api + "api/users";

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<IUser[]>(this.apiUrl);
  }

  create(user: UserInputForm) {
    return this.http.post<IUser>(this.apiUrl, user);
  }

  update(userId: string, user: UserInputForm) {
    return this.http.put<IUser>(`${this.apiUrl}/${userId}`, user);
  }

  delete(userId: string) {
    return this.http.delete<IUser>(`${this.apiUrl}/${userId}`);
  }

  toggleActive(userId: string, isActive: boolean) {
    return this.http.post<IUser>(`${this.apiUrl}/actions/toggle-is-active`, { id: userId, isActive });
  }
}
