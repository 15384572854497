import { Injectable } from "@angular/core";
import { createStore } from "@ngneat/elf";
import {
  deleteEntities,
  getActiveEntity,
  getAllEntities,
  selectAllEntities,
  setActiveId,
  setEntities,
  upsertEntities,
  withActiveId,
  withEntities,
} from "@ngneat/elf-entities";
import { ICurrency } from "app/_shared/types/models/setting";
import { take } from "rxjs";

const store = createStore({ name: "currencies" }, withEntities<ICurrency, "_id">({ idKey: "_id" }), withActiveId());

@Injectable({ providedIn: "root" })
export class CurrencyStore {
  findAll$ = store.pipe(selectAllEntities(), take(1));

  data = () => store.query(getAllEntities()).filter((entity) => !entity.isDeleted);
  default = () => store.query(getActiveEntity());

  setEntities(state: ICurrency[]) {
    store.update(setEntities(state));
  }

  updateEntities(state: ICurrency[]) {
    store.update(upsertEntities(state));
  }

  removeEntities(ids: string[]) {
    store.update(deleteEntities(ids));
  }

  setActiveEntity(id: string) {
    store.update(setActiveId(id));
  }

  destroy() {
    store.reset();
  }
}
