import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { AgGridModule } from "ag-grid-angular";
import { provideGlobalGridOptions } from "ag-grid-community";
import {
  rowClassRulesMap,
  columnTypesMap,
  componentMap,
  contextMenuItems,
  excelStyles,
  sideBar,
} from "app/_shared/utils/ag-grid.utils";

provideGlobalGridOptions({
  sideBar,

  columnMenu: "new",
  cacheQuickFilter: true,
  suppressDragLeaveHidesColumns: true,
  includeHiddenColumnsInQuickFilter: true,

  rowClassRules: rowClassRulesMap,
  columnTypes: columnTypesMap,
  components: componentMap,
  getContextMenuItems: () => contextMenuItems,
  getRowId: (params) => params.data?._id,
  excelStyles,

  defaultColDef: {
    flex: 1,
    minWidth: 150,
  },
});

@NgModule({
  imports: [CommonModule, AgGridModule],
  declarations: [],
  exports: [AgGridModule],
})
export class AgGridSetupModule {}
