import { Component, Input } from "@angular/core";

@Component({
  selector: "app-bool",
  template: `
    @if (value) {
      <i nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="'#8CBC3F'"></i>
    } @else {
      <i nz-icon nzType="close-circle" nzTheme="twotone" [nzTwotoneColor]="'#de4436'"></i>
    }
  `,
})
export class BoolComponent {
  @Input() value: boolean;
}
