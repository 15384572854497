import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ICompany } from "app/_shared/types/models/company";

import { environment } from "environments/environment";
import { map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  apiUrl: string = environment.api + "api/companies";

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<ICompany[]>(this.apiUrl);
  }

  getOne(companyId: string) {
    return this.http.get<ICompany>(`${this.apiUrl}/${companyId}`);
  }

  create(company: any) {
    return this.http.post<ICompany>(this.apiUrl, company);
  }

  update(companyId: string, company: any) {
    return this.http.put<ICompany>(`${this.apiUrl}/${companyId}`, company);
  }

  delete(companyId: string) {
    return this.http.delete<ICompany>(`${this.apiUrl}/${companyId}`);
  }

  toggleActive(companyId: string, isActive: boolean) {
    return this.http.post<ICompany>(`${this.apiUrl}/actions/toggle-is-active`, { id: companyId, isActive });
  }

  notCompany() {
    return this.http.post<ICompany[]>(`${this.apiUrl}/actions/not-company`, {});
  }

  warehouses() {
    return this.getAll().pipe(
      map((companies) =>
        companies.filter((company) => company.types.map(({ displayName }) => displayName).includes("WAREHOUSE")),
      ),
    );
  }

  updateCounter(companyId: string, counter: number) {
    return this.http.post<ICompany>(`${this.apiUrl}/actions/counter`, { id: companyId, counter });
  }
}
