import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { TableService } from "app/_shared/services/table.service";
import { TableStore } from "app/_shared/stores/entities/table.store";
import { CurrencyService } from "app/_shared/services/currency.service";
import { CurrencyStore } from "app/_shared/stores/entities/currency.store";
import { CompanyService } from "app/_shared/services/company.service";
import { CompanyStore } from "app/_shared/stores/entities/company.store";
import { ProductService } from "app/_shared/services/product.service";
import { ProductStore } from "app/_shared/stores/entities/product.store";
import { CommentService } from "app/_shared/services/comment.service";
import { CommentStore } from "app/_shared/stores/entities/comment.store";
import { AppStore } from "app/_shared/stores/app.store";
import { switchMap, forkJoin, catchError, of, tap, map } from "rxjs";
import { Permission } from "app/_shared/types/models/role";
import { environment } from "environments/environment";
import { OrderService } from "app/_shared/services/order.service";
import { OrderStore } from "app/_shared/stores/entities/order.store";
import { MsalService } from "@azure/msal-angular";
import { scopes } from "app/_shared/utils/app.utils";

@Injectable({
  providedIn: "root",
})
export class AppService {
  constructor(
    private http: HttpClient,
    private msalService: MsalService,
    private appStore: AppStore,
    private tableService: TableService,
    private tableStore: TableStore,
    private currencyService: CurrencyService,
    private currencyStore: CurrencyStore,
    private companyService: CompanyService,
    private companyStore: CompanyStore,
    private productService: ProductService,
    private productStore: ProductStore,
    private orderService: OrderService,
    private orderStore: OrderStore,
    private commentService: CommentService,
    private commentStore: CommentStore,
  ) {}

  init() {
    this.serverAvailable()
      .pipe(
        tap(() => this.appStore.setState({ isLoading: true })),
        switchMap(() => {
          return forkJoin([
            this.getPermissions(),
            this.currencyService.getAll().pipe(catchError(() => of([]))),
            this.companyService.getAll().pipe(catchError(() => of([]))),
            this.productService.getAll().pipe(catchError(() => of([]))),
            this.orderService.fetchAll().pipe(catchError(() => of([]))),
            this.commentService.getAll(0).pipe(catchError(() => of([]))),
            this.tableService.getOne().pipe(catchError(() => of(null))),
          ]);
        }),
      )
      .subscribe({
        next: ([permissions, currencies, companies, products, orders, comments, table]) => {
          this.appStore.setState({ permissions, error: false });
          this.currencyStore.setEntities(currencies);
          this.currencyStore.setActiveEntity(currencies.find((currency) => currency.code === "USD")?._id);
          this.companyStore.setEntities(companies);
          this.productStore.setEntities(products);
          this.orderStore.setEntities(orders);
          this.commentStore.setEntities(comments);
          this.tableStore.setEntities(table?.tables ?? []);
          this.tableService.observe();
        },
        error: (err) => {
          this.appStore.setState({ error: true, status: err.status, permissions: [] });
        },
        complete: () => {
          this.appStore.setState({ isLoading: false });
        },
      });
  }

  acquireToken() {
    return this.msalService.initialize().pipe(
      switchMap(() => this.msalService.acquireTokenSilent({ scopes })),
      map((response) => response.accessToken),
      catchError(() => of(null)),
    );
  }

  serverAvailable() {
    return this.http.get(environment.api + "api");
  }

  getPermissions() {
    return this.http.get<Permission[]>(`${environment.api}auth/permissions`);
  }

  saveFromUrl(name: string, url: string, mimeType: string) {
    if (!url || !mimeType) return;
    this.http.get(url, { responseType: "blob", headers: { "content-type": mimeType } }).subscribe((file) => {
      file && saveAs(file, name);
    });
  }
}
