import { Component, Input, OnInit } from "@angular/core";
import { ILog, ILogProperty, logFieldNameMap } from "app/_shared/types/models/log";
import { has, isArray, isString, startCase } from "lodash";

@Component({
  selector: "app-log-drawer-properties",
  template: `
    <div class="w-full">
      <ul class="list-inside text-sm">
        @for (property of properties; track property.name) {
          <li>
            <div class="flex">
              <div class="capitalize w-3/12 truncate font-semibold">
                <span>&#8226;</span>
                {{ property.name }}
              </div>
              @if (log.action === "updated") {
                <div class="w-4/12">
                  <ol [ngClass]="property.oldValue.length > 1 ? 'list-[circle]' : 'list-none'">
                    @for (value of property.oldValue; track $index) {
                      <li>{{ value }}</li>
                    }
                  </ol>
                </div>
                <div class="w-1/12">
                  <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
                </div>
              }
              <div [ngClass]="log.action === 'updated' ? 'w-4/12' : 'w-9/12'">
                <ol [ngClass]="property.newValue.length > 1 ? 'list-[circle]' : 'list-none'">
                  @for (value of property.newValue; track $index) {
                    <li>{{ value }}</li>
                  }
                </ol>
              </div>
            </div>
          </li>
        }
      </ul>
    </div>
  `,
  styles: [],
})
export class LogDrawerPropertiesComponent implements OnInit {
  @Input() log: ILog;

  properties: ILogProperty[] = [];

  ngOnInit(): void {
    //todo fix this by model
    this.properties = this.log.properties
      .filter(
        (property) =>
          ![
            "country",
            "relatedCountry",
            "relatedSourceCountry",
            "relatedDestinationCountry",
            "relatedOrderType",
            "sourceCountry",
            "destinationCountry",
            "orderType",
          ].includes(property.name),
      )
      .map((property) => {
        return {
          name: has(logFieldNameMap, property.name) ? logFieldNameMap[property.name] : startCase(property.name),
          oldValue:
            property.oldValue == null
              ? []
              : isArray(property.oldValue)
                ? property.oldValue
                : isString(property.oldValue) && property.oldValue.includes(" | ")
                  ? property.oldValue.split(" | ")
                  : [property.oldValue],
          newValue:
            property.newValue == null
              ? []
              : isArray(property.newValue)
                ? property.newValue
                : isString(property.newValue) && property.newValue.includes(" | ")
                  ? property.newValue.split(" | ")
                  : [property.newValue],
        };
      });
  }
}
