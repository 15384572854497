import { LicenseManager } from "ag-grid-enterprise";
import { ApplicationRef } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { enableElfProdMode } from "@ngneat/elf";
import { devTools } from "@ngneat/elf-devtools";
import * as Sentry from "@sentry/angular";

import { AppModule } from "app/app.module";

import { environment } from "environments/environment";

Sentry.init({
  environment: environment.development ? "development" : environment.production ? "production" : "local",
  dsn: "https://32cf8c8e6d6ab52fee02d0bc0d002b8c@o4506433008566272.ingest.sentry.io/4506439552204800",
  // This enables automatic instrumentation (highly recommended),
  // but is not necessary for purely manual usage
  // If you only want to use custom instrumentation:
  // * Remove the `BrowserTracing` integration
  // * add `Sentry.addTracingExtensions()` above your Sentry.init() call
  integrations: [Sentry.browserTracingIntegration()],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://nuxapp-dev.herokuapp.com/", "https://nuxapp-v3.herokuapp.com/"],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableElfProdMode();
}

LicenseManager.setLicenseKey(environment.agGridLicense);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    devTools({
      postTimelineUpdate: () => moduleRef.injector.get(ApplicationRef).tick(),
    });
  })
  .catch((err) => console.error(err));
