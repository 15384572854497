<div
  class="flex justify-between w-full"
  [ngClass]="{
    'py-2 pl-2 pr-10 border radius-lg border-light-silver rounded-sm': !isCollapse
  }">
  <div class="flex flex-col">
    <div class="my-1 flex space-x-0.5">
      <span>
        <app-user-avatar [user]="log.user" [size]="16" />
      </span>
      <span>{{ log.user.displayName | startCase }}</span>
      <span class="font-semibold">{{ log.action | startCase }}</span>
      <span class="font-bold text-carrot-orange-500">{{ name | startCase }}</span>
    </div>
    <div class="my-1 flex space-x-0.5">
      <span>
        <nz-avatar nzSize="small" nzShape="square" [nzIcon]="icon" [nzSize]="16" [ngStyle]="{ 'background-color': '#5D5C61' }"></nz-avatar>
      </span>
      <span>{{ log.title }}</span>
    </div>
  </div>
  <div class="flex items-center min-w-fit">
    <span nz-icon nzType="clock-circle" class="mr-1"></span>
    <span>{{ log.createdAt | date: "dd/MM/yyyy HH:mm" }}</span>
  </div>
</div>
